export const Style = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: { md: "flex-start", xs: "center" },
    width: "100%",
  },
  leftContainer: { width: "100%", maxWidth: { lg: "65%", md: "100%" } },
  rightContainer: {
    width: "100%",
    maxWidth: { lg: "35%", md: "100%" },
    display: "flex",
    flexDirection: { lg: "column", md: "row" },
    alignItems: { lg: "flex-end", md: "center" },
    justifyContent: { lg: "flex-start", md: "center", xs: "center" },
    flexWrap: "wrap",
  },
};
