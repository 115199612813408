import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomButton, CustomTable, Loader } from "../../../components";
import { selectdrawer } from "../../../store/slice/drawer";
import { selectMyTeams, selectUser } from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import { IMAGES } from "../../../theme";
import CompanyWrapper from "./CompanyWrapper";
import { Style } from "./style";

const CompanySummary = () => {
  const state = useLocation()?.state;
  const data = state?.data;
  const myTeams = useSelector(selectMyTeams);
  const { id } = useParams();
  const { drawer } = useSelector(selectdrawer);
  const { access, company_id, isSuperAdmin, company_name, teams } =
    useSelector(selectUser);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  return (
    <CompanyWrapper
      heading={company_name || data?.name}
      topBannerIcon={IMAGES.companies}
      topBannerHeading={ isSuperAdmin ? "Companies" : "My Teams"}
      handleBack={() =>
        isSuperAdmin
          ? navigate(`/companies`, { state })
          : navigate("/dashboard")
      }
    >
      <Box sx={Style.main}>
        <Typography sx={{ ...globalStyle.headings, mb: 2 }}>
          Admins / Users List
        </Typography>
        <Box sx={!loading && Style.tableContainer(drawer)}>
          {loading ? (
            <Loader
              style={{
                height: "unset",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <>
              {(id || company_id) && <CustomTable />}
              <CustomTable isuser />
            </>
          )}
        </Box>
        {!loading && (
          <CustomButton
            sx={Style.btn}
            icon={<AddIcon />}
            buttonText={`Create Admin / User`}
            onClick={() =>
              navigate(
                isSuperAdmin
                  ? `/company/${id}/create/admin`
                  : `/my-teams/create/user`,
                { state }
              )
            }
          />
        )}
      </Box>
    </CompanyWrapper>
  );
};

export default CompanySummary;
