import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutModal } from "../../components";
import { userSideOptions } from "../../helper/navigationHelper";
import useWindowDimensions from "../../hooks/windowDimensions";
import { updateDrawer } from "../../store/slice/drawer";
import { selectUser } from "../../store/slice/user";
import { IMAGES } from "../../theme/images";
import { Style } from "./Style";

const DrawerChildren = ({ open, setOpenEditModal, setOpenLogoutModal }) => {
  // const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { isAdmin, isSuperAdmin, isUser, first_name, last_name, email } = useSelector(selectUser);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const handleDrawerClose = (path) => {
    if (width <= 600) {
      dispatch(updateDrawer({ drawer: false }));
    } else if (!path) {
      dispatch(updateDrawer({ drawer: !open }));
    }
    path && navigate(path);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const name = first_name + " " + last_name;
  const activeColor = (path) => {
    return (
      "/" + location?.pathname?.split("/")[1] ===
        (path === "/companies" && location?.pathname?.split("/")?.length > 2
          ? location?.pathname?.split("/")[1] === "companies"
            ? "/companies"
            : "/company"
          : path) && "#ffff"
    );
  };
  return (
    <>
      <Box>
        {open && (
          <Box sx={Style.logoContainer}>
            <Box component={"img"} src={IMAGES.logo} sx={Style.logo} />
          </Box>
        )}
        <DrawerHeader>
          <IconButton onClick={() => handleDrawerClose()}>
            {!open ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        {open && (
          <DrawerHeader>
            <Box sx={Style.headerContainer}>
              <AccountCircleIcon sx={Style.headerIcon} />
              <Typography variant="h6" sx={Style.userNameContainer}>
                <Typography
                  sx={{ ...Style.text, ...Style.ellipse, width: "auto" }}
                >
                  {name}
                </Typography>
                <EditIcon
                  style={Style.editIcon}
                  onClick={() => setOpenEditModal(true)}
                />
              </Typography>
              <Typography sx={[Style.text, Style.ellipse]} variant="h6">
                {email}
              </Typography>
            </Box>
          </DrawerHeader>
        )}
        <Divider />
        <List>
          {userSideOptions(isAdmin, isSuperAdmin, isUser)
            .filter(Boolean)
            .map(({ title, icon, path }, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "block",
                  background: activeColor(path),
                }}
                onClick={() => handleDrawerClose(path)}
              >
                <ListItemButton sx={Style.listItemBtnContainer(open)}>
                  <ListItemIcon sx={Style.listItemIcon(open)}>
                    <Box
                      component={"img"}
                      src={icon}
                      alt="icon"
                      sx={{ height: "26px", width: "30px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 19 }}
                    primary={title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
      <Box>
        <Divider />
        <Box sx={Style.signoutContainer}>
          <Typography
            variant="h6"
            sx={Style.signoutText}
            onClick={() => setOpenLogoutModal(true)}
          >
            {open && "Log Out"} &nbsp;
            <LogoutIcon />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default DrawerChildren;
