import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { Style } from "./style";

const Loader = ({ style }) => {
  return (
    <Box sx={{ ...Style.main, ...style }}>
      <CircularProgress size={100} />
    </Box>
  );
};

export default Loader;
