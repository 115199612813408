import { Box, Typography } from "@mui/material";
import React from "react";
import { Style } from "./style";

const UserQuizCard = ({
  heading,
  subHeading,
  onClick = () => {},
  iconClick = () => {},
  sx = {},
  subHeadingStyle = {},
  topRightIcon,
  headingStyle = {},
  contentContainer = {},
  score
}) => {
  return (
    <Box component={"div"} sx={{ ...Style.card, ...sx }}>
      {topRightIcon && score !== 0 && (
        <Box onClick={iconClick} sx={Style.topRightIcon}>
          {topRightIcon}
        </Box>
      )}
      <Box
        onClick={onClick}
        mt={topRightIcon && -3}
        sx={{ ...Style.contentContainer, ...contentContainer }}
      >
        <Typography sx={{ ...Style.headings, ...headingStyle }}>
          {heading}
        </Typography>
        {subHeading && (
          <Typography sx={{ ...Style.subHeading, ...subHeadingStyle }}>
            {subHeading}
          </Typography>
        )}
        { score === 0 && typeof score === "number" && 
        <Typography sx={{...Style.headings, ...headingStyle, fontSize: '18px'}}>
          Retry
        </Typography>
        }
      </Box>
    </Box>
  );
};

export default UserQuizCard;
