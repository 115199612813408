import { globalStyle } from "../../../../styles/globalStyle";

export const style = {
  wrapper: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: { 
      xs: 0, sm: "0 10px"
      , md: "0px 30px" },
  },
  contanier: {
    width: "100%",
    maxWidth: "1274px",
    border: "1px solid  #BEA5A5 ",
  },
  header: {
    maxWidth: "1274px",
    height: { xs: "120px", md: "80px" },
    backgroundColor: "#02416F",
    display: "flex",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: { xs: "center", md: "space-between" },
    border: "1px solid #BEA5A5",
    p: "0px 10px",
  },
  questionSectionWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    p: "16px 20px ",
    pb: "0px",
  },
  questionSectionContanier: {
    maxWidth: "1185px",
    width: "100%",
    height: "100%",
    border: "1px solid #BEA5A5 ",
  },
  questionSectionHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#02416F",
    p: "12px 20px",
    color: "white !important",
    fontSize: "18px",
  },
  optionListWrapper: { display: "flex", flexDirection: "column" },
  optionList: {
    backgroundColor: "white",
    margin: "10px 0px",
    boxShadow: "rgba(0, 0, 0, 0.15)2px 5px 2.6px;",
    p: "8px 20px",
  },
  bottomBtnWrapper: {
    display: "flex",
    justifyContent: "felx-end",
    maxWidth: "1185px",
    flexDirection: "row-reverse",
    width: "100%",
  },
  bottomBtnContainer: {
    display: "flex",
    margin: "20px 0px",
    gap: "16px",
  },
  quizHeading: {
    ...globalStyle.headings,
    color: "white !important",
    cursor: "auto",
  },
  correctOptionContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  boldHeading: {
    fontSize: "16",
    color: "#000000",
    fontWeight: 800,
  },
  loader: { width: "100%", height: "auto" },
};
