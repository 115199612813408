import {
  Box,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomButton,
  Loader,
  NoRecordFound,
  ReuseModal,
} from "../../../../components";
import { selectUser } from "../../../../store/slice/user";
import {
  getQuestionListData,
  handleAnswerChange,
  handleClose,
  handleNext,
  handleOptionChange,
  handlePrevious,
  handleQuestionChange,
  updateQuizData,
  uploadQuizData,
} from "./helper";
import { style } from "./style";

const UploadQuiz = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation()?.state;
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [index, setIndex] = useState(0);
  const selectedIndex = questionsList[index];
  const isLastIndex = index === questionsList.length - 1;
  const { id, docId, departId } = useParams();
  const { isAdmin, access } = useSelector(selectUser);
  const [formData, setFormData] = useState({
    question: selectedIndex?.question || "",
    options: selectedIndex?.options || [],
    answer: selectedIndex?.answer || "",
  });

  const handleSubmit = () => {
    !isLastIndex
      ? handleNext({ setIndex })
      : isAdmin && !data?.upload_status
      ? setOpen(true)
      : handleClose({
          isAdmin,
          navigate,
          id,
          departId,
          docId,
          state,
        });
  };
  useEffect(() => {
    getQuestionListData({
      dispatch,
      navigate,
      data,
      access,
      setQuestionsList,
      setLoader,
    });
  }, [data?.id]);

  useEffect(() => {
    setFormData({
      question: selectedIndex?.question || "",
      options: selectedIndex?.options || [],
      answer: selectedIndex?.answer || "",
    });
  }, [index, questionsList]);

  useEffect(() => {
    setIsChanged(
      formData.question !== selectedIndex?.question ||
        JSON.stringify(formData.options) !==
          JSON.stringify(selectedIndex?.options) ||
        formData.answer !== selectedIndex?.answer
    );
  }, [formData, selectedIndex]);

  if (loader) return <Loader style={style.loader} />;
  return (
    <>
      {!questionsList?.length && !loader ? (
        <NoRecordFound />
      ) : (
        <Box sx={style.wrapper}>
          <Box sx={style.contanier}>
            <Box sx={style.header}>
              <Typography sx={style.quizHeading}>
                {data?.index > -1 ? `Quiz: ${data?.index + 1}` : `Upload Quiz`}
              </Typography>
              <Box
                sx={{
                  gap: "16px",
                  display: "flex",
                  mt: { xs: 1, sm: 0 },
                  alignItems: "center",
                }}
              >
                <Typography sx={style.quizHeading}>
                  {`${index + 1}/${questionsList.length}`}
                </Typography>
                <CustomButton
                  onClick={() => {
                    handlePrevious({ setIndex, index });
                  }}
                  disable={!index || updateLoader || isChanged}
                  icon={<GrLinkPrevious size={24} />}
                />
                <CustomButton
                  buttonText={
                    !isLastIndex
                      ? ""
                      : isAdmin && !data?.upload_status
                      ? "Upload"
                      : "Close"
                  }
                  onClick={handleSubmit}
                  icon={!isLastIndex && <GrLinkNext size={24} />}
                  disable={updateLoader || isChanged}
                />
              </Box>
            </Box>
            <Box sx={style.questionSectionWrapper}>
              <Box sx={style.questionSectionContanier}>
                <Box sx={{ padding: "16px" }}>
                  {!isAdmin && (
                    <Typography sx={style.questionSectionHeader}>
                      Q{index + 1}: {selectedIndex?.question}
                    </Typography>
                  )}
                  <List sx={style.optionListWrapper}>
                    {isAdmin && (
                      <ListItem sx={style.optionList}>
                        <ListItemIcon>
                          <Typography sx={style.boldHeading}>
                            Q{index + 1}:
                          </Typography>
                        </ListItemIcon>
                        <Input
                          multiline={true}
                          value={formData?.question}
                          onChange={(e) =>
                            handleQuestionChange({ e, setFormData })
                          }
                          fullWidth
                        />
                      </ListItem>
                    )}
                    {formData.options.map((option, optionIndex) =>
                      Object.entries(formData.options[0]).map(
                        ([key, value], index) => (
                          <ListItem
                            key={`${optionIndex}-${index}`}
                            sx={style.optionList}
                          >
                            <ListItemIcon>
                              <Typography sx={style.boldHeading}>
                                {key}
                              </Typography>
                            </ListItemIcon>
                            {isAdmin ? (
                              <Input
                                value={value}
                                onChange={(e) =>
                                  handleOptionChange(
                                    optionIndex,
                                    key,
                                    e.target.value,
                                    setFormData
                                  )
                                }
                                fullWidth
                              />
                            ) : (
                              <ListItemText primary={value} />
                            )}
                          </ListItem>
                        )
                      )
                    )}
                  </List>
                  <Box sx={style.correctOptionContainer}>
                    <Typography sx={style.boldHeading}>
                      Correct Option:
                    </Typography>
                    {!isAdmin ? (
                      <Typography sx={style.boldHeading}>
                        {formData?.answer}
                      </Typography>
                    ) : (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Option"
                        value={formData.answer}
                        onChange={(e) => handleAnswerChange({ e, setFormData })}
                        defaultValue={formData.answer}
                        sx={{
                          "& .MuiSelect-select": {
                            border: "2px solid #000000 !important",
                          },
                        }}
                      >
                        {Object?.keys(selectedIndex?.options[0] || {}).map(
                          (value, index) => (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={style.bottomBtnWrapper}>
                <Box sx={style.bottomBtnContainer}>
                  {isAdmin && (
                    <CustomButton
                      disable={!isChanged}
                      buttonText={"Update"}
                      onClick={() =>
                        updateQuizData({
                          dispatch,
                          navigate,
                          setUpdateLoader,
                          selectedIndex,
                          formData,
                          access,
                          questionsList,
                          index,
                          setIsChanged,
                        })
                      }
                      loading={updateLoader}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <ReuseModal
            title={"Are you sure you want to upload quiz ?"}
            open={open}
            setOpen={setOpen}
            onConfirm={() =>
              uploadQuizData({
                dispatch,
                access,
                departId,
                docId,
                state,
                setOpen,
                navigate,
                setUploadLoader,
                setQuestionsList,
                selectedIndex,
              })
            }
            loader={uploadLoader}
          />
        </Box>
      )}
    </>
  );
};

export default UploadQuiz;
