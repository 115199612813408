import { Box, Typography } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";
import { style } from "./Style";

const StartQuizModal = ({ open, setOpen, onConfirm }) => {
  const { result_status, score, attempt_status, index } = open;
  return (
    <ModalWrapper open={!!open} setOpen={setOpen} sx={style.wrapper}>
      <Box sx={style.header}>
        <Typography sx={globalStyle.headings}>QUIZ {index + 1}</Typography>
        <RxCross2 style={style.clossIcon} onClick={() => setOpen(false)} />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography sx={style.headerText}>
          {attempt_status == "Attempted" ? "Attempted" : "Not Attempted"}
        </Typography>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {score !== null && (
            <Typography sx={{ fontSize: "30px", color: "#000" }}>
              Score :{" "}
              <Typography component={"span"} sx={{ fontSize: "30px" }}>
                {score}%
              </Typography>
            </Typography>
          )}
          {result_status !== null && (
            <Typography sx={{ fontSize: "30px", color: "#000" }}>
              Status :{" "}
              <Typography
                component={"span"}
                sx={{
                  fontSize: "30px",
                  color: result_status == "Pass" ? "#007B05" : "#C20B0B",
                }}
              >
                {result_status}
              </Typography>
            </Typography>
          )}
          {/* <Typography sx={{ fontSize: "30px", color: "#000" }}>
            No of Attemps :
            <Typography
              component={"span"}
              sx={{ fontSize: "30px", color: "#007B05" }}
            >
              1
            </Typography>
          </Typography> */}
          {attempt_status == "Attempted" && (
            <Typography sx={style.bottomText}>
              *you can reattempt the Quiz to improve your score
            </Typography>
          )}
          <CustomButton
            buttonText={`${
              attempt_status == "Attempted" ? "Retake" : "Start"
            } Quiz`}
            typSx={globalStyle.headings}
            sx={style.startQuizButton}
            onClick={onConfirm}
          />
        </Box>
      </Box>
    </ModalWrapper>
  );
};
export default StartQuizModal;
