import { COLORS } from "../../../theme";

export const Style = {
  // Commented names are the components names of dashboard //

  // Calendar
  datePicker: { width: "100%", maxWidth: 320, margin: 0 },
  // CardsSection
  cardContainer: {
    display: "flex",
    flexDirection: { xs: 'column', sm: 'row' },
    flexWrap: "wrap",
    my: 3,
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: {xs: 'center'}
  },
  cardItem: { mr: { sm: 2 }, mb: 2 },
  // CourseHeading
  courseContainer: { display: "flex", my: 3, mb: 5 },
  courseHeading: {
    fontSize: { lg: 25, md: 23, sm: 20, xs: 18 },
    fontWeight: "bold",
  },
  courseDescription: {
    fontSize: { lg: 23, md: 21, sm: 19, xs: 17 },
    ml: { xs: 0, md: 2 },
  },
  browseBtn: {
    backgroundColor: COLORS.golden,
    color: COLORS.black,
    fontWeight: "bold",
    marginTop: 1,
    ml: { xs: 0, md: 2 },
  },
  browseBtnHover: {
    backgroundColor: COLORS.lightGolden,
    color: COLORS.black,
  },

  // ProgressSection
  LinearProgressWithLabel: { display: "flex", alignItems: "center", mb: 2 },
  progressSection: { width: "100%", maxWidth: 320 },
  // RecentActivities
  activityContainer: {
    display: "flex",
    justifyContent: "space-between",
    my: 2,
  },
  // RoundedBoxSection
  roundSectionContainer: { display: "flex", alignItems: "center", mb: 2 },
  roundSectionSubContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: { lg: "flex-start", md: "flex-start", xs: "center" },
  },
  gradiantContainer: {
    borderRadius: 10,
    background: "linear-gradient(180deg, #9BB3F2 10%, rgba(255,255,255,1) 65%)",
    padding: "50px 30px 60px 30px",
    maxWidth: 298,
    width: { lg: "auto", sm: "100%", xs: "100%" },
    maxHeight: 243,
    height: 223,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    mr: { lg: 2, sm: 2, xs: 0 },
  },
  tittle: {
    fontSize: { lg: 30, md: 29, sm: 27, xs: 25 },
    textAlign: { xs: "center", sm: "center", md: "flex-start" },
  },
  description: {
    fontSize: { lg: 27, md: 26, sm: 25, xs: 22 },
    textAlign: { xs: "center", sm: "center", md: "flex-start" },
  },
};
