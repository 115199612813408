import { Box, Typography } from "@mui/material";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";

const DeleteModal = ({ open, setOpen, onConfirm, onClose, loading }) => {
  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <Typography sx={globalStyle.subHeading}>
        Do you want to delete ?
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <CustomButton onClick={onClose} color="secondary" buttonText={"NO"} />
        <CustomButton
          loading={loading}
          onClick={onConfirm}
          color="secondary"
          buttonText={"YES"}
        />
      </Box>
    </ModalWrapper>
  );
};
export default DeleteModal;
