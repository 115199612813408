export const Style = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: (isDrawer) => ({
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    padding: { xs: "14px", sm: "20px" },
    boxSizing: "border-box",
    gap: { xs: "20px", md: "40px", lg: "60px" },
    background: "#EBF5F9",
    minWidth: "223px",
    maxWidth: {xs: "100vw", sm: isDrawer ? "calc(100vw - 282px)" : "calc(100vw - 108px)", md: isDrawer ? "calc(100vw - 305px)" : "calc(100vw - 90px)", lg: isDrawer ? "calc(100vw - 280px)" : "calc(100vw - 100px)", xl: "1700px"},
    flexWrap: "wrap",
    width: "100%",
    height: {
      xs: "fit-content",
    },
  }),
  btn: { alignSelf: "flex-end", mt: "16px" },
};
