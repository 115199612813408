import { globalStyle } from "../../styles/globalStyle";

export const Style = {
  cardWrapper: (drawer) => ({
    display: "flex",
    gap: "26px",
    flexWrap: "wrap",
    width: "fit-content",
    justifyContent: { xs: "center", md: !drawer ? "center" : "start" },
  }),
  customCardWrapper: {
    display: "flex",
    gap: "40px",
    flexWrap: "wrap",
    justifyContent: { xs: "center", md: "inherit" },
  },
  bgWrapper: { display: "flex", flexDirection: "column", flexGrow: 1 },
  quizContainer: {
    display: "flex",
    background: "#EDF6FC",
    justifyContent: "space-between",
    flexDirection: "column",
    flexGrow: 1,
    p: "16px",
    borderRadius: "16px",
  },
  question: {
    ...globalStyle.headings,
    whiteSpace: "normal",
    cursor: "auto",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    mt: "16px",
    ml: "10px",
  },
  alignEndBtn: { width: "fit-content", alignSelf: "end" },
  scroreWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    p: "16px",
    borderRadius: "16px",
    background: "#EDF6FC",
  },
  scoreContainer: {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1000px",
    backgroundColor: "#FFFFFF",
    padding: { xs: "20px", sm: "40px" },
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  attemptMsg: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "40px", md: "50px" },
  },
  score: {
    ...globalStyle.headings,
    color: "#00000",
    textAlign: "center",
    cursor: "auto",
  },
  percentage: { fontSize: "56px", mt: { xs: "20px", sm: "40px" } },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "column", sm: "row" },
    flexGrow: 1,
    gap: { xs: "10px", md: "20px" },
    boxSizing: "border-box",
    flexWrap: "nowrap",
    pb: "5px",
  },
};
