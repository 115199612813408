import { Box, CssBaseline } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditProfileModal, LogoutModal } from "../../components";
import useWindowDimensions from "../../hooks/windowDimensions";
import { selectdrawer } from "../../store/slice/drawer";
import DrawerChildren from "./DrawerChildren";
import SmallScreenSlider from "./SmallScreenSlider";
import { Style } from "./Style";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "#e6e6fb",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  justifyContent: "space-between",
  overflowX: "hidden",
  border: "none",
});

const closedMixin = (theme) => ({
  background: "#e6e6fb",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  justifyContent: "space-between",
  overflowX: "hidden",
  border: "none",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const { width } = useWindowDimensions();
  const { drawer } = useSelector(selectdrawer);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [open, setOpen] = useState(drawer);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);
  return (
    <Box sx={Style.container}>
      <CssBaseline />
      {width > 600 ? (
        <Drawer variant="permanent" open={open}>
          <DrawerChildren open={open} setOpenEditModal={setOpenEditModal} setOpenLogoutModal={setOpenLogoutModal} />{" "}
        </Drawer>
      ) : (
        <SmallScreenSlider open={open} setOpen={setOpen}>
          <DrawerChildren open={open} setOpenEditModal={setOpenEditModal} setOpenLogoutModal={setOpenLogoutModal} />
        </SmallScreenSlider>
      )}
      <EditProfileModal open={openEditModal} setOpen={setOpenEditModal} />
      <LogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
      
      <Box component="main" sx={Style.childrenWrapper}>
        <Box sx={Style.bgHeader(drawer)} />
        <Box sx={Style.childrenContainer(drawer)}>{children}</Box>
      </Box>
    </Box>
  );
}
