import { useState } from "react";
import {
  Activate,
  Companies,
  Dashboard,
  ForgetPassword,
  ResetPassword,
  SignIn,
  Training,
} from "../screens";
import { IMAGES } from "../theme";
import AddCompany from "../screens/companies/add";
import CompanySummary from "../screens/companies/summary";
import CreateUser from "../screens/companies/createUser";
import TrainingDepartments from "../screens/training/Departments";
import TrainingDocuments from "../screens/training/Documents";
import DocumentSummary from "../screens/training/DocumentsSummary";

export const appRoutes = (isAdmin, isSuperAdmin, isUser, role, Navigate) => {
  return [
    // general routes
    ...(!role ? unAuthenticatedRoutes : []),
    // auth common routes
    ...(isSuperAdmin || isAdmin || isUser ? authenticatedRoutes : []),
    // user routes
    ...(isUser ? userRoutes : []),
    // admin routes
    ...(isAdmin ? adminRoutes(isAdmin) : []),
    // super admin routes
    ...(isSuperAdmin ? superAdminRoutes(isAdmin) : []),

    {
      path: "*",
      element: <Navigate to={`${!role ? "/signin" : "/dashboard"}`} replace />,
    },
  ];
};
export const userSideOptions = (isAdmin, isSuperAdmin, isUser) => {
  return [
    {
      title: "Dashboard",
      icon: IMAGES.dashboard,
      slug: "dashboard",
      path: "/dashboard",
    },
    isAdmin && {
      title: "My Teams",
      icon: IMAGES.companies,
      slug: "teams",
      path: "/my-teams",
    },
    isUser && {
      title: "My Learning",
      icon: IMAGES.training,
      slug: "learning",
      path: "/my-learning",
    },
    isSuperAdmin && {
      title: "Companies",
      icon: IMAGES.companies,
      slug: "companies",
      path: "/companies",
    },
    (isSuperAdmin || isAdmin) && {
      title: "Trainings",
      icon: IMAGES.training,
      slug: "training",
      path: "/trainings",
    },
  ];
};

const unAuthenticatedRoutes = [
  { path: "/signin", element: <SignIn /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  {
    path: "/password/reset/confirm/:uid/:token",
    element: <ResetPassword />,
  },
  { path: "/activate/:uid/:token", element: <Activate /> },
];

const authenticatedRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];

const userRoutes = [
  {
    path: "/my-learning", element:
      <TrainingDepartments />
  },
  {
    path: "/my-learning/department/:departId", element:
      <TrainingDocuments />
  },
  {
    path: "/my-learning/department/:departId/document/:docId/:section", element:
      <DocumentSummary />
  },
];

const commonRoutes = (isAdmin) => {
  return [
    {
      path: "/trainings",
      element: isAdmin ? <TrainingDepartments /> : <Training />,
    },
  ];
};

const adminRoutes = (isAdmin) => [
  { path: "/my-teams", element: <CompanySummary /> },
  { path: "/my-teams/create/user", element: <CreateUser /> },
  { path: "/my-teams/create/admin", element: <CreateUser /> },
  { path: "/my-teams/update/user/:user_id", element: <CreateUser /> },
  { path: "/my-teams/update/admin/:user_id", element: <CreateUser /> },
  { path: "/trainings/department/:departId/document/:docId/:section", element: <DocumentSummary /> },
  {
    path: "/trainings/department/:departId",
    element: <TrainingDocuments />,
  },
  ...commonRoutes(isAdmin),
];

const superAdminRoutes = (isAdmin) => [
  ...commonRoutes(isAdmin),
  { path: "/companies", element: <Companies /> },
  { path: "/companies/add", element: <AddCompany /> },
  { path: "/company/update/:id", element: <AddCompany /> },
  { path: "/company/:id", element: <CompanySummary /> },
  {
    path: "/company/:id/create/user",
    element: <CreateUser />,
  },
  {
    path: "/company/:id/create/admin",
    element: <CreateUser />,
  },
  {
    path: "/company/:id/update/user/:user_id",
    element: <CreateUser />,
  },
  {
    path: "/company/:id/create/admin",
    element: <CreateUser />,
  },
  {
    path: "/company/:id/update/admin/:user_id",
    element: <CreateUser />,
  },
  {
    path: "/trainings/company/:id/department/:departId/document/:docId/:section",
    element: <DocumentSummary />,
  },
  {
    path: "/trainings/company/:id/department/:departId",
    element: <TrainingDocuments />,
  },
  {
    path: "/trainings/company/:paramsId",
    element: <TrainingDepartments />,
  },
];
