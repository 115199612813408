import email from "../assets/baseEmail.png";
import companies from "../assets/companies.png";
import companyLogo from "../assets/companyLogo.png";
import dashboard from "../assets/dashboard.png";
import google from "../assets/google.png";
import leftbanner from "../assets/leftBannner.png";
import logo from "../assets/logo.png";
import microsoft from "../assets/microsoft.png";
import training from "../assets/training.png";
import uploadDocument from "../assets/uploadDocument.png";
import document from "../assets/document.webp";
import department from "../assets/department.png";

const IMAGES = {
  training,
  google,
  microsoft,
  email,
  companyLogo,
  leftbanner,
  companies,
  dashboard,
  uploadDocument,
  logo,
  department,
  document
};
export { IMAGES };
