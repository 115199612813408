import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Input, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleError } from "../../hooks/globalFunction";
import { updateDepartments } from "../../services/departments";
import { selectUser, updateUserData } from "../../store/slice/user";
import { IMAGES } from "../../theme";
import ViewUsers from "../Modals/ViewUsers";
import { Style } from "./style";

const PreviewCards = ({
  isDownload = true,
  data = {},
  handleClick = () => { },
  deleteIconClick = () => { },
  editIconClick = () => { },
  isEdit,
  isDelete,
  editItem,
  setEditItem,
  index,
}) => {
  const { departId, docId } = useParams();
  const { heading, paragraph, date, name } = data;
  const [inputVal, setInputVal] = useState(editItem?.item?.name);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { access, departments } = useSelector(selectUser);
  const dispatch = useDispatch();
  const defaultDepartment = useMemo(() => {
    return IMAGES.department;
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation()
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const defaultDocument = useMemo(() => {
    return IMAGES.document;
  }, []);


  useEffect(() => {
    setInputVal(editItem?.item?.name);
  }, [editItem]);

  const updateDepartmentName = async () => {
    try {
      setLoader(true);
      const { data } = await updateDepartments(
        dispatch,
        navigate,
        { name: inputVal },
        editItem?.item?.id,
        access
      );
      let tempData = [...departments?.results];
      tempData[editItem?.index] = data;
      dispatch(updateUserData({ departments: { results: tempData } }));
      if (data?.id) {
        navigate("/trainings/");
      }
      toast.success("Department updated successfully");
      setLoader(false);
      setEditItem(false);
    } catch (err) {
      toast.error(handleError({ name: inputVal }, err?.response?.data));
      setLoader(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    updateDepartmentName();
  };

  const handleDownload = (e) => {
    e.stopPropagation()
    window.open(
      typeof data?.file == "string"
        ? data?.file
        : `https://app.traino.ai/media/documents/` + data?.name
    );
  };
  const condition = editItem?.index === index && !!editItem

  const assigned_data = data?.assigned_users_details

  return (
    <>
      <Card sx={Style.main} onClick={handleClick}>
        {date && (
          <Typography sx={Style.uploadDate}>Uploaded date : {date}</Typography>
        )}
        <Box sx={Style.contentContainer}>
          <Typography sx={Style.content}>
            <CardMedia
              component={"img"}
              sx={{
                height: !!departId ? "180px" : "173px",
                background: "none",
                objectFit: 'contain'
              }}
              src={!!departId ? defaultDocument : defaultDepartment}
            />
          </Typography>
          <CardContent
            style={Style.contentBox}
          >
            <form id={condition && "no_change"} onSubmit={submit} style={Style.title} onClick={(e) => condition && e.stopPropagation()}>
              {!departId && <DisplaySettingsIcon
                sx={Style.pointerCusrsor}
              />}
              {condition ? (
                <Input
                  sx={Style.input}
                  value={inputVal}
                  onChange={(e) => setInputVal(e?.target?.value)}
                  id={"no_change"}
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                <Tooltip title={heading || name}>
                  <Typography
                    onClick={handleClick}
                    sx={{ ...Style.pointerCusrsor, ...Style.ellipse, textAlign: !!departId && 'center', fontWeight: !!departId && 'bold' }}
                  >
                    {heading || name}
                  </Typography>
                </Tooltip>
              )}
              {!departId &&
                <>
                  {isDownload && (
                    <DownloadIcon sx={Style.pointerCusrsor} onClick={handleDownload} id={"no_change"} />
                  )}
                  {isEdit &&
                    (!!editItem && editItem?.index === index ? (
                      <>
                        <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                          <CloseIcon
                            sx={Style.pointerCusrsor}
                            onClick={() => setEditItem(false)}
                          />
                        </Button>
                        <Button sx={Style.button} type={"submit"} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                          <DoneIcon sx={Style.pointerCusrsor} />
                        </Button>
                      </>
                    ) : (
                      <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                        <EditIcon onClick={editIconClick} sx={Style.pointerCusrsor} />
                      </Button>
                    ))}
                  {isDelete && isEdit && (!editItem || editItem?.index !== index) && (
                    <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                      <DeleteIcon sx={Style.pointerCusrsor} onClick={deleteIconClick} />
                    </Button>
                  )}
                </>
              }
            </form>
            <Box sx={{ m: !!departId && '30px 0 24px' }}>
              {!!departId &&
                <>
                  <Typography>
                    <Typography component={"span"} sx={Style.bold}>Summary Generated: </Typography>
                    <Typography component={"span"} >{data?.is_summary ? " YES" : "NO"}</Typography>
                  </Typography>
                  <Typography>
                    <Typography component={"span"} sx={Style.bold}>Key Points Generated: </Typography>
                    <Typography component={"span"} >{data?.is_keypoints ? " YES" : "NO"}</Typography>
                  </Typography>
                  {/* <Typography>
                    <Typography component={"span"} sx={Style.bold}>Quiz Generated: </Typography>
                    <Typography component={"span"} >{data?.is_quizzes ? "YES" : "NO"}</Typography>
                  </Typography> */}
                  <Typography>
                    <Typography component={"span"} sx={Style.bold}>No of Quizzes: </Typography>
                    <Typography component={"span"} >{data?.quizzes || 0}</Typography>
                  </Typography>
                  <Typography>
                    <Typography component={"span"} sx={Style.bold}>
                      {`Assigned to: `}
                    </Typography>
                    <Typography component={"a"} onClick={handleOpen} sx={{ color: '#3346D3', textDecoration: 'underline' }}>
                      {data?.assigned_users?.length || 0} {data?.assigned_users?.length > 1 ? "users" : "user"}
                    </Typography>
                  </Typography>
                </>
              }
            </Box>
          </CardContent >
          {!!departId &&
            <CardActions sx={{ width: "100%", p: '8px 0' }}>
              {isDownload && (
                <DownloadIcon sx={Style.pointerCusrsor} onClick={handleDownload} id={"no_change"} />
              )}
              {isEdit &&
                (!!editItem && editItem?.index === index ? (
                  <>
                    <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                      <CloseIcon
                        sx={Style.pointerCusrsor}
                        onClick={() => setEditItem(false)}
                      />
                    </Button>
                    <Button sx={Style.button} type={"submit"} id={"no_change"} onClick={(e) => {
                      e.stopPropagation()
                      updateDepartmentName();
                    }}>
                      <DoneIcon sx={Style.pointerCusrsor} />
                    </Button>
                  </>
                ) : (
                  <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                    <EditIcon onClick={editIconClick} sx={Style.pointerCusrsor} />
                  </Button>
                ))}
              {isDelete && isEdit && (!editItem || editItem?.index !== index) && (
                <Button sx={Style.button} id={"no_change"} onClick={(e) => e.stopPropagation()}>
                  <DeleteIcon sx={Style.pointerCusrsor} onClick={deleteIconClick} />
                </Button>
              )}
            </CardActions>
          }
        </Box>
      </Card>
      <ViewUsers open={open} setOpen={setOpen} data={assigned_data} />
    </>
  );
};

export default PreviewCards;
