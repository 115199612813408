import { globalStyle } from "../../styles/globalStyle";

export const Style = {
  main: {
    width: {
      xs: "100%",
      md: "calc(50% - 13px)",
      lg: "calc(33% - 15px)",
      xl: "calc(25% - 20px)",
    },
    maxWidth: {xs: "calc(100vw - 24px)", sm: "400px"},
    minWidth: {xs: '200px', sm: "250px"},
    mt: "30px",
    cursor: 'pointer',
    display: 'flex'
  },
  uploadDate: {
    ...globalStyle.subHeading,
    fontSize: "12px",
    textAlign: "end",
    pr: 1,
  },
  contentContainer: {
    width: '100%',
    background: "#DDE1EC",
    p: "16px",
    borderRadius: "5px",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap', 
    alignItems: 'start',
  },
  content: {
    width: '100%',
    height: "183px",
    background: "#FFFFFF",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "black",
    borderRadius: "8px",
    overflow: "visible",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: 'nowrap',
    overflow: 'hidden',
    gap: "9px",
    pt: "16px",
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    overflow: 'hidden',
    width: '100%',
    padding: '16px 0',
    paddingBottom: '0'
  },
  pointerCusrsor: { cursor: "pointer" },
  ellipse: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: '100%'
  },
  button: {
    minWidth: "32px",
    height: '20px',
    padding: "0 5px"
  },
  input: { height: '24px', display: 'flex', width: '100%' },
  bold: {
    fontWeight: 'bold'
  }
};
