import { COLORS } from "../theme";

export const globalStyle = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    flexGrow: 1,
  },
  HeroBanner: {
    pt: "20px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  headings: {
    fontWeight: 600,
    fontSize: { xs: "20px", sm: "24px" },
    lineHeight: 1.5,
    letterSpacing: "2px",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
  },
  headingMain: {
    display: "inline !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subHeading: {
    color: COLORS.black,
    fontWeight: 600,
    fontSize: { xs: "14px", md: "18px" },
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  arrowback: {
    fontSize: { xs: 25, sm: 28, md: 32, lg: 35 },
    cursor: "pointer",
  },
  divider: { my: 2, borderStyle: "solid", borderWidth: "2px" },
  wrapper: {
    py: {
      xs: "24px",
      md: "30px",
    },
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    alignItems: "start",
  },
  cardsWrapper: (drawer) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    mt: "40px",
    justifyContent: {
      xs: "center",
      sm: drawer ? "center" : "start",
      md: "start",
    },
    pb: "24px",
  }),
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    width: "100%",
    gap: { xs: "6px", md: "20px" },
    boxSizing: "border-box",
    flexWrap: "nowrap",
    pb: "5px",
  },
  tabs: (isActive) => ({
    width: { xs: "70px", md: "120px" },
    minWidth: "fit-content",
    fontSize: { xs: "10px", md: "16px" },
    p: { xs: "6px 8px", md: "6px 16px" },
    background: isActive ? "#10194B" : "#FFF",
    color: isActive ? "#FFF !important" : "#000 !important",
    "&:hover": {
      background: "#10194B",
      color: "#FFF !important",
      opacity: 0.8,
    },
  }),
  tabsMain: (isTabs, isDrawer) => ({
    pl: { xs: 2.5, sm: 2.5 },
    width: {
      xs: "calc(100vw - 55px)",
      sm: isDrawer ? "calc(100vw - 315px)" : "calc(100vw - 140px)",
      md: isDrawer ? "calc(100vw - 350px)" : "calc(100vw - 180px)",
    },
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
    overflow: !!isTabs ? "auto" : "hidden",
    scrollbarWidth: "none",
  }),
  tabsTyp: {
    fontSize: { xs: "14px", md: "16px" },
  },
};
