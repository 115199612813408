import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TopBanner } from "../../components";
import Layout from "../../layout/MainLayout";
import { selectUser } from "../../store/slice/user";
import { IMAGES } from "../../theme";
import Calendar from "./components/Calendar";
import CardsSection from "./components/CardsSection";
import ChartSection from "./components/ChartSection";
import CourseHeading from "./components/CourseHeading";
import PerformanceChart from "./components/PerformanceChart";
import ProgressSection from "./components/ProgressSection";
import RoundedBoxSection from "./components/RoundedBoxSection";
import { Style } from "./style";

const Dashboard = () => {
  const { isAdmin, isSuperAdmin } = useSelector(selectUser);
  let isLocalAdmin = isAdmin || isSuperAdmin;

  return (
    <Layout>
      <TopBanner Icon={IMAGES.dashboard} text={"Dashboard"} />
      <Box sx={Style.container}>
        <Box sx={Style.leftContainer}>
          <CardsSection />
          {!isLocalAdmin && <CourseHeading />}
          <RoundedBoxSection />
          <PerformanceChart />
        </Box>
        <Box sx={Style.rightContainer}>
          <Calendar />
          {isAdmin && <ProgressSection />}
          <ChartSection />
        </Box>
      </Box>
    </Layout>
  );
};

export default Dashboard;
