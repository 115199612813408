import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { Style } from "./style";

const NoRecordFound = () => {
  return (
    <Box sx={Style.main}>
      <Typography sx={Style.pera}>No Record Found!</Typography>
    </Box>
  );
};

export default NoRecordFound;
