export const Style = {
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: { xs: "center", sm: "space-between" },
    flexDirection: { xs: "column", md: "row" },
    gap: "16px",
  },
  headingContainer: {
    color: "#2E1B3ACC",
    display: "flex",
    alignItems: "center",
    gap: "18px",
    width: { xs: "100%", sm: "auto" },
  },
  menuIconContainer: { xs: "block", sm: "none" },
  menuIcon: { fontSize: "36px" },
  icon: { height: { xs: 40, md: 60 }, width: { xs: 50, md: 80 } },
  pageIconContainer: {
    display: "flex",
    gap: { xs: "6px", md: "18px", alignItems: "start" },
  },
  text: {
    fontSize: { xs: "22px", md: "30px" },
    fontWeight: 800,
  },
  divider: { borderWidth: "1.5px", borderColor: "#2E1B3ACC" },
  optionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: { xs: "100%", md: "auto" },
  },
  searchWrapper: {
    p: "2px 10px 2px 4px",
    display: "flex",
    alignItems: "center",
    width: { xs: "100%" },
    minWidth: { xs: "100px", md: "200px" },
    borderRadius: "50px",
  },
};
