import { COLORS } from "../../../theme";

export const Style = {
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    borderRadius: "4px",
    p: "16px 8px",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formContainer: {
    background: COLORS.white,
    borderRadius: "16px",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "0px", md: "20px" },
    p: { xs: 0, sm: "0 10px", md: "0 20px" },
  },
  formSection: {
    mt: { xs: 2.5, md: 0 },
    display: "flex",
    flexDirection: "row",
    gap: "0 20px",
    width: { xs: "100%", md: "100%" },
    p: { xs: "16px 10px", sm: "16px 20px", md: "10px 0", lg: "10px 20px" },
    boxSizing: "border-box",
    flexWrap: "wrap",
  },
  fieldsWrapper: () => ({
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "end",
    maxWidth: "720px",
    pb: 0,
    ml: 0,
    mr: 0,
  }),
  label: () => ({
    width: "100%",
    fontSize: { xs: "14px", lg: "16px" },
    textAlign: "start",
    marginRight: "10px",
  }),
  textField: {
    width: {
      xs: "100%",
      md: "100%",
    },
    maxwidth: "800px",
    textalign: "end",
  },
  btnContainer: {
    mt: 3,
    display: "flex",
    gap: "15px",
    justifyContent: "end",
  },
  input: {
    width: "100%",
    margin: 0,
    color: "black",
    borderRadius: "8px",
    height: "52px",
    fontSize: "16px",
    lineHeight: "18px",
    border: "none",
    outline: 'none'
  },
  button: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  dropDown: {
    width: "100%",
    background: COLORS.white,
    position: "absolute",
    top: "56px",
    // padding: '0 16px',
    border: '1px solid rgb(200, 200, 200)',
    textAlign: "start",
    color: COLORS.black,
    boxSizing: "border-box",
    overflowY: "auto",
    zIndex: '10',
    height: '230px',
  },
  containerStyle: {
    overflow: "hidden",
    boxSizing: "border-box",
  },
  searchStyle: {
    boxSizing: "border-box",
    width: "100%",
    height: "40px",
    padding: "0 9px",
    position: "absolute",
    top: 0,
    left: 0,
  },
  inputProps: {
    name: "",
    required: true,
    autoFocus: true,
  },
  createForm: {
    height: "128px",
    width: { xs: "100%", md: 'calc(50% - 10px)' },
  }
};
