import { Box } from "@mui/material";
import React from "react";
import { SignInFrom } from "../../components";
import LeftImageSection from "../../components/LeftImageSection";
import { globalStyle } from "../../styles/globalStyle";

const SignIn = () => {
  return (
    <Box sx={globalStyle.main}>
      <LeftImageSection />
      <SignInFrom />
    </Box>
  );
};

export default SignIn;
