import CampaignIcon from "@mui/icons-material/Campaign";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Divider } from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDrawer } from "../../store/slice/drawer";
import { selectUser } from "../../store/slice/user";
import Notification from "../Dropdowns/Notification";
import { Style } from "./style";

const TopBanner = ({ text, Icon, currentComponent }) => {
  const { access } = useSelector(selectUser);
  const [notification, setNotifation] = useState(null);
  const notificationDropdown = Boolean(notification);
  const dispatch = useDispatch();
  const openDrawer = () => {
    dispatch(updateDrawer({ drawer: true }));
  };

  const handleClick = (event) => {
    setNotifation(event.currentTarget);
  };
  const onClose = () => {
    setNotifation(null);
  };

  return (
    <Box sx={Style.main}>
      <Box sx={Style.headingContainer}>
        <Box
          component={"div"}
          onClick={openDrawer}
          display={Style.menuIconContainer}
        >
          <MenuIcon style={Style.menuIcon} />
        </Box>
        <Box sx={Style.pageIconContainer}>
          <Box component={"img"} src={Icon} alt="icon" sx={Style.icon} />
          <Box sx={Style.text}>
            {text}
            <Divider sx={Style.divider} />
          </Box>
        </Box>
      </Box>
      <Box sx={Style.optionsContainer}>
        <Paper component="form" sx={Style.searchWrapper}>
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ "aria-label": "Search..." }}
          />
        </Paper>
        <Badge color="error" badgeContent={4}>
          <NotificationsActiveIcon
            onClick={handleClick}
            sx={{ fontSize: 30, cursor: "pointer", mr: "5px" }}
            id={"notification"}
          />
        </Badge>
        <CampaignIcon sx={{ fontSize: 40, cursor: "pointer" }} />
      </Box>
      <Notification
        id={"notification"}
        handleClose={onClose}
        anchorEl={notification}
        open={notificationDropdown}
      />
    </Box>
  );
};

export default TopBanner;
