import { globalStyle } from "../../styles/globalStyle";

export const Style = {
  wrapper: { display: "flex", flexDirection: "column", flexGrow: 1 },
  titleContainer: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    borderRadius: "50px",
    border: "1px solid #10194B",
    mt: "16px",
    px: { xs: "16px", md: "40px" },
    py: "16px",
    wordBreak: "break-all",
  },
  header: {
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row" },
    justifyContent: "space-between",
    alignItems: "center",
  },
  paraText: {
    ...globalStyle.headings,
    color: "#968F8F",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "2px",
  },
  addIcon: {
    color: "#968F8F",
    marginTop: "10px",
    fontSize: "60px",
    cursor: "Pointer",
  },
};
