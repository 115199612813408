import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomButton,
  Loader,
  NoRecordFound,
  ReuseModal,
} from "../../../components";
import { handleError } from "../../../hooks/globalFunction";
import { getQuestionList, submitQuizByUser } from "../../../services/quiz";
import { uploadQuizzAction } from "../../../store/slice/quizzez";
import { selectUser } from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import { Style } from "../style";

const Question = () => {
  const { access } = useSelector(selectUser);
  const { departId, docId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state;
  const quizData = state?.quizData;
  const selectedDocData = state?.val;
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [submitData, setSubmitData] = useState({});
  const [index, setIndex] = useState(0);
  const selectedIndex = questionsList[index];
  const isLastIndex = index !== questionsList.length - 1;

  const getQuestionsListData = async () => {
    if (!!quizData?.id) {
      try {
        setLoader(true);
        const res = await getQuestionList(
          dispatch,
          navigate,
          quizData?.id,
          access
        );
        setQuestionsList(res.data);
        setSubmitData({ quiz_id: res?.data[0]?.quiz, answers: [] });
      } catch (err) {
        toast.error(handleError({}, err?.response?.data));
      } finally {
        setLoader(false);
      }
    }
  };

  const handleSubmitData = (val) => {
    let data;
    const itemIndex = submitData.answers.findIndex(
      (item) => item.question_id === val.question_id
    );
    if (itemIndex !== -1) {
      data = [
        ...submitData.answers.slice(0, itemIndex),
        val,
        ...submitData.answers.slice(itemIndex + 1),
      ];
    } else {
      data = [...submitData.answers, val];
    }
    setSubmitData((prev) => ({ ...prev, answers: data }));
  };

  const submitQuizData = async () => {
    try {
      setSubmitLoader(true);
      const res = await submitQuizByUser(
        dispatch,
        navigate,
        submitData,
        access
      );
      dispatch(
        uploadQuizzAction({
          id: quizData?.id,
          attempt_status: "Attempted",
          result_status: res?.data?.Status,
          score: res?.data?.Score,
        })
      );
      navigate(
        `/my-learning/department/${departId}/document/${docId}/quizResult`,
        {
          state: { val: selectedDocData, quizData, quizResult: res.data },
        }
      );
      setSubmitData({});
      setOpen(false);
    } catch (err) {
      toast.error(handleError({}, err?.response?.data));
    } finally {
      setSubmitLoader(false);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 1);
  };
  const handlePrevious = () => {
    if (!!index) setIndex((prev) => prev - 1);
  };

  useEffect(() => {
    getQuestionsListData();
  }, [quizData?.id]);

  if (loader) return <Loader style={{ width: "100%", height: "auto" }} />;
  return (
    <>
      {!questionsList?.length ? (
        <NoRecordFound />
      ) : (
        <Box sx={Style.bgWrapper}>
          <Typography sx={{ ...globalStyle.headings, cursor: "auto" }}>
            Question {`${index + 1}/${questionsList.length}`}
          </Typography>
          <Box sx={Style.quizContainer}>
            <Box>
              <Typography sx={Style.question}>
                Q{`${index + 1}`}: {selectedIndex?.question}
              </Typography>
              <Box sx={Style.optionsContainer}>
                {selectedIndex?.options?.[0] &&
                  Object.entries(selectedIndex.options[0]).map(
                    ([key, value], ind) => {
                      return (
                        <FormControlLabel
                          style={{
                            width: "fit-content",
                          }}
                          key={ind}
                          control={
                            <Checkbox
                              checked={
                                key === submitData?.answers[index]?.option
                              }
                              onClick={() =>
                                handleSubmitData({
                                  question_id: selectedIndex?.id,
                                  option: key,
                                })
                              }
                              name="option"
                            />
                          }
                          label={
                            <Typography variant="body1">{value}</Typography>
                          }
                        />
                      );
                    }
                  )}
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}
            >
              <CustomButton
                onClick={() => {
                  handlePrevious();
                }}
                disable={!index}
                buttonText={"Previous"}
              />
              <CustomButton
                onClick={() => {
                  isLastIndex ? handleNext() : setOpen(true);
                }}
                disable={
                  !submitData?.answers?.some(
                    (el) => el.question_id == selectedIndex?.id
                  )
                }
                buttonText={isLastIndex ? "Next" : "Submit"}
              />
            </Box>
          </Box>
          <ReuseModal
            title={"Are you sure you want to submit quiz ?"}
            open={open}
            setOpen={setOpen}
            onConfirm={submitQuizData}
            loader={submitLoader}
          />
        </Box>
      )}
    </>
  );
};

export default Question;
