import { COLORS, IMAGES } from "../../theme";

export const Style = {
  main: {
    width: "60%",
    backgroundImage: `url(${IMAGES.leftbanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: { xs: "none", md: "block" },
  },
  content: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: COLORS.white,
  },
  welcomeHeading: {
    fontFamily: "serif",
    fontWeight: 900,
    fontSize: "40px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  appName: {
    fontFamily: "serif",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  slogan: {
    fontFamily: "serif",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
};
