import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../hooks/globalFunction";
import { getQuizResult } from "../../services/quiz";
import { selectdrawer } from "../../store/slice/drawer";
import { selectUser } from "../../store/slice/user";
import { Style } from "./Style";
import { globalStyle } from "../../styles/globalStyle";

const QuizResults = () => {
  const { drawer } = useSelector(selectdrawer);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizId = location?.state?.quizData?.id;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState();
  const { access } = useSelector(selectUser);

  const getQuizResultData = async () => {
    if (quizId) {
      try {
        setIsLoading(true);
        const res = await getQuizResult(dispatch, navigate, quizId, access);
        let resData = res?.data?.results;
        resData?.map((item, index) => (item.id = index));
        setData(resData);
      } catch (err) {
        toast.error(
          handleError(
            {},
            err?.response?.data || { error: "Something went wrong" }
          )
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const columns = [
    {
      field: `user_name`,
      headerName: "Name",
      width: 150,
      sortable: true,
    },
    {
      field: `score`,
      headerName: "Score",
      width: 100,
      sortable: true,
    },
    {
      field: `status`,
      headerName: "Status",
      width: 100,
      sortable: true,
    },
  ];

  useEffect(() => {
    getQuizResultData();
  }, []);

  return (
    <Box sx={Style.main(drawer)}>
      <Typography
        textAlign={"start"}
        sx={{ ...globalStyle.headings, cursor: "auto" }}
      >
        Quiz Result
      </Typography>
      <Box width={"100%"}>
        <Box sx={Style.tittleContainer}>
          <Typography sx={Style.tittle}>Users Details</Typography>
        </Box>
        <DataGrid
          sx={Style.tableContainer}
          rows={data || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          loading={isLoading}
          rowCount={data?.length}
        />
      </Box>
    </Box>
  );
};

export default QuizResults;
