import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddSummaryKeyPoints, Chatbot, QuizResults } from "../../../components";
import { handleError } from "../../../hooks/globalFunction";
import { generateQuiz, getQuizList } from "../../../services/quiz";
import {
  addQuizzezAction,
  generateQuizzAction,
} from "../../../store/slice/quizzez";
import { selectSummaryAndKeyPoints } from "../../../store/slice/summaryAndKeyPoints";
import { selectUser } from "../../../store/slice/user";
import { IMAGES } from "../../../theme";
import CompanyWrapper from "../../companies/summary/CompanyWrapper";
import Question from "../../myLearning/components/Question";
import QuizSection from "../../myLearning/components/QuizSection";
import Result from "../../myLearning/components/Result";
import UploadQuiz from "../Components/UploadQuiz/uploadQuiz";

const DocumentSummary = () => {
  const location = useLocation();
  const state = location?.state;
  const quizData = state?.quizData;
  const selectedDocData = state?.val;
  const documentId = selectedDocData?.id;
  const [quizLoader, setQuizLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const { id, departId, docId, section } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAdmin, isUser, access } = useSelector(selectUser);
  const { selectedDocument } = useSelector(selectSummaryAndKeyPoints);

  const addQuiz = async () => {
    const data = { document: documentId };
    try {
      setLoader(true);
      const res = await generateQuiz(dispatch, navigate, data, access);
      dispatch(generateQuizzAction({ ...res?.data, upload_status: false }));
      navigate(
        `/trainings/department/${departId}/document/${docId}/uploadQuiz`,
        {
          state: { ...state, quizData: res.data },
        }
      );
      toast.success("Quiz generated successfully");
    } catch (err) {
      toast.error(
        handleError(
          {},
          err?.response?.data || { error: "Something went wrong" }
        )
      );
    } finally {
      setLoader(false);
    }
  };

  const getQuizListData = async () => {
    if (!!documentId) {
      try {
        setQuizLoader(true);
        const res = await getQuizList(dispatch, navigate, documentId, access);
        dispatch(addQuizzezAction(res?.data));
      } catch (err) {
        toast.error(handleError({}, err?.response?.data));
      } finally {
        setQuizLoader(false);
      }
    }
  };

  const handleBack = () => {
    if (isAdmin && section === "quiz") {
      navigate(`/trainings/department/${departId}/document/${docId}/quizzes`, {
        state,
      });
    } else if (
      isUser &&
      (section === "quizResult" || section === "attemptQuiz")
    ) {
      navigate(
        `/my-learning/department/${departId}/document/${docId}/quizzes`,
        {
          state,
        }
      );
    } else if (
      isAdmin &&
      (section === "uploadQuiz" || section === "quizResult")
    ) {
      navigate(`/trainings/department/${departId}/document/${docId}/quiz`, {
        state,
      });
    } else if (
      isSuperAdmin &&
      (section === "uploadQuiz" || section === "quizResult")
    ) {
      navigate(
        `/trainings/company/${id}/department/${departId}/document/${docId}/quizzes`,
        {
          state,
        }
      );
    }
  };

  useEffect(() => {
    getQuizListData();
  }, [selectedDocument]);

  return (
    <CompanyWrapper
      isTabs={true}
      topBannerIcon={IMAGES.training}
      topBannerHeading={ isUser ? "My Learning" : "Trainings"}
      heading={section === "quiz" && "Uploaded Quizzes"}
      handleBack={handleBack}
    >
      <Box sx={{ display: "flex", width: "100%", flex: 1 }}>
        {section === "summary" || section === "keyPoints" ? (
          <Chatbot />
        ) : (isAdmin || isSuperAdmin) && section === "uploadQuiz" ? (
          <UploadQuiz data={quizData} />
        ) : isAdmin && section === "quizzes" ? (
          <AddSummaryKeyPoints
            paraText={"Generate Quiz"}
            handleClick={addQuiz}
            loading={loader}
            quizLoader={quizLoader}
          />
        ) : isUser && section === "attemptQuiz" ? (
          <Question />
        ) : isUser && section === "quizResult" ? (
          <Result />
        ) : !isUser && section === "quizResult" ? (
          <QuizResults />
        ) : (
          <QuizSection loader={quizLoader} />
        )}
      </Box>
    </CompanyWrapper>
  );
};
export default DocumentSummary;
