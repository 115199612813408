import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  CircularProgress,
  Divider,
  GlobalStyles,
  IconButton,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { Style } from "./style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addSummary, getSummary } from "../../services/summary";
import {
  selectSummaryAndKeyPoints,
  selectedDocumentAction,
  addSummaryAction,
  addKeyPointsAction,
} from "../../store/slice/summaryAndKeyPoints";
import { addKeypoints, getKeypoints } from "../../services/keypoints";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, updateUserData } from "../../store/slice/user";
import toast from "react-hot-toast";
import { handleError } from "../../hooks/globalFunction";

const Chatbot = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDocData = location?.state?.val;
  const selectedId = selectedDocData?.id;
  const { section } = useParams();
  const { access, isAdmin, documents } = useSelector(selectUser);
  const { summary, keyPoints, selectedDocument } = useSelector(
    selectSummaryAndKeyPoints
  );
  const [inputVal, setInputVal] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const getData = async (condition) => {
    setLoading(true);
    if (!!section && condition) {
      if (
        (selectedDocument?.is_summary && section == "summary") ||
        (selectedDocument?.is_keypoints && section == "keyPoints") ||
        Object.keys(selectedDocument).length === 0
      )
        getApiFunc()
          .then((data) => {
            setData(data);
            changeInputVal(true);
            setLoading(false);
          })
          .catch(() => {
            setData(
              isAdmin
                ? `To generate ${
                    section == "keyPoints" ? "keypoint" : "summary"
                  } please click below button`
                : `${
                    section == "summary" ? "Summary" : "Keypoint"
                  } has not been generated yet.`
            );
            changeInputVal();
            setLoading(false);
          });
      else {
        setData(
          isAdmin
            ? `To generate ${
                section == "keyPoints" ? "keypoint" : "summary"
              } please click below button`
            : `${
                section == "summary" ? "Summary" : "Keypoint"
              } has not been generated yet.`
        );
        changeInputVal();
        setLoading(false);
      }
    } else setLoading(false);
  };

  useEffect(() => {
    let condition =
      section == "summary"
        ? typeof summary == "string"
          ? !summary
          : !summary?.data
        : typeof keyPoints == "string"
        ? !keyPoints
        : !keyPoints?.data;
    if (condition) getData(condition);
    else {
      changeInputVal();
      setLoading(false);
    }
  }, [section]);

  const changeInputVal = (isEmpty) => {
    let tempData = section === "summary" ? summary : keyPoints;
    setInputVal(
      (!!tempData?.data && !!tempData?.data[section?.toLowerCase()]) || isEmpty
        ? ""
        : section === "summary"
        ? `You are the content creator , Provide me the descriptive, related and concise and everytime unique summary for:`
        : `You are the key-points generator , Provide me the keypoints in bullet-points, related to the and everytime provide me unique keypoints :`
    );
  };

  const getApiFunc = async () => {
    return section?.toLowerCase() === "summary"
      ? await getSummary(dispatch, navigate, access, `${selectedId}`)
      : await getKeypoints(dispatch, navigate, access, `${selectedId}`);
  };

  const addApiFunc = async () => {
    return section?.toLowerCase() === "summary"
      ? await addSummary(
          dispatch,
          navigate,
          { document: selectedId, prompt: inputVal },
          access
        )
      : await addKeypoints(
          dispatch,
          navigate,
          { document: selectedId, prompt: inputVal },
          access
        );
  };

  const setData = (data) => {
    section?.toLowerCase() === "summary"
      ? dispatch(addSummaryAction(data))
      : dispatch(addKeyPointsAction(data));
  };

  const submit = async (e) => {
    e.preventDefault();
    let selectedDocumentObj = { ...selectedDocument };
    if (inputVal?.trim()?.length)
      try {
        setSubmitLoading(true);
        await addApiFunc()
          .then((data) => {
            if (data) {
              dispatch(
                section === "summary"
                  ? addSummaryAction(data)
                  : addKeyPointsAction(data)
              );
              selectedDocumentObj[`is_${section?.toLowerCase()}`] = true;
              dispatch(selectedDocumentAction(selectedDocumentObj));
              let allDocuments = { ...documents };
              let selectedInd = allDocuments?.results?.findIndex(
                (item) => item?.id == selectedDocumentObj?.id
              );
              allDocuments.results = [
                ...allDocuments?.results?.slice(0, selectedInd),
                selectedDocumentObj,
                ...allDocuments?.results?.slice(selectedInd + 1),
              ];
              dispatch(updateUserData({ documents: allDocuments }));
              toast.success("Prompt send successfully");
              setInputVal("");
              setSubmitLoading(false);
            }
          })
          .catch((err) => {
            if (!!err?.response)
              toast.error(handleError({}, err?.response?.data));
            setInputVal("");
            setSubmitLoading(false);
          });
      } catch (err) {
        if (!!err?.response) toast.error(handleError({}, err?.response?.data));
        setSubmitLoading(false);
      }
  };

  let points = [];
  if (!!keyPoints?.data ?? keyPoints?.data[section]) {
    const keyPointsData = keyPoints?.data[section.toLowerCase()];
    points = keyPointsData
      ? keyPointsData.split("\n").filter((line) => line.trim() !== "")
      : [];
  }

  return (
    <Box sx={Style.wrapper}>
      <Box sx={Style.titleContainer}>
        <DisplaySettingsIcon sx={{ fontSize: "40px" }} />
        <Typography sx={GlobalStyles.headings}>
          {section?.charAt(0).toUpperCase() + section?.slice(1)}
        </Typography>
      </Box>
      <Box sx={Style.contentContainer}>
        {loading ? (
          <Box sx={Style.loaderContainer}>
            <CircularProgress size={60} />
          </Box>
        ) : section === "summary" ? (
          <Typography sx={Style.content}>
            {!!summary?.data
              ? summary?.data[section?.toLowerCase()]
              : typeof summary == "string" && summary}
          </Typography>
        ) : (
          <Typography sx={Style.content}>
            {!!keyPoints?.data
              ? points.length
                ? points?.map((value, index) => (
                    <>
                      <Typography
                        component={"span"}
                        key={index}
                        sx={Style.points}
                      >
                        {value}
                      </Typography>
                      <br />
                    </>
                  ))
                : null
              : typeof keyPoints == "string" && keyPoints}
          </Typography>
        )}
        {isAdmin && (
          <Paper component="form" onSubmit={submit} sx={Style.inputWrapper}>
            <InputBase
              value={inputVal}
              onChange={(e) => setInputVal(e?.target?.value)}
              sx={Style.inputBase}
              placeholder="Ask here..."
            />
            <Divider sx={Style.divider} orientation="vertical" />
            <IconButton
              type="submit"
              color="primary"
              sx={Style.sendIcon}
              aria-label="directions"
              disabled={!inputVal?.trim()?.length}
            >
              {submitLoading ? <CircularProgress size={24} /> : <SendIcon />}
            </IconButton>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default Chatbot;
