export const Style = {
  wrapper: { display: "flex", flexDirection: "column", flexGrow: 1 },
  titleContainer: { display: "flex", gap: "15px", alignItems: "center" },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    borderRadius: "50px",
    border: "1px solid #10194B",
    mt: "16px",
    px: { xs: "16px", md: "40px" },
    // pr: '40px',
    py: "16px",
    overflow: 'hidden',
    wordBreak: "break-all",
  },
  content: {
    height: '470px',  // Adjust height as needed for different screen sizes
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F1F1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(16, 25, 75)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgb(16, 25, 85)',
    },
    fontSize: "18px",
    fontWeight: "700",
    wordSpacing: "10px",
    letterSpacing: "2px",
    color: "rgba(0, 0, 0, 0.8)",
    wordBreak: "break-word",  // corrected property name
  },
  
  points: {
    fontSize: "18px",
    display: 'block',
    fontWeight: "700",
    wordSpacing: "10px",
    letterSpacing: "2px",
    color: "rgba(0, 0, 0, 0.8)",
    overflowY: "auto",
    overflow: "auto",
    "word-break": "auto-phrase",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    mt: "10px",
    background: "#E1F2FFB8",
    borderRadius: "50px",
    px: "4px",
  },
  inputBase: { ml: 1, flex: 1 },
  divider: { height: 28, m: 0.5 },
  sendIcon: { p: "10px" },
  loaderContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};
