import { COLORS } from "../../../../theme";

export const Style = {
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    background: "#EDF6FC",
    flexGrow: 1,
    borderRadius: "4px",
    p: "16px 8px",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formContainer: {
    background: COLORS.white,
    borderRadius: "16px",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "0px", md: "20px" },
    p: { xs: 0, sm: "0 10px", md: "0 20px" },
  },
  formSection: {
    mt: { xs: 2.5, md: 0 },
    display: "flex",
    flexDirection: { xs: 'column', md: "row" },
    justifyContent: 'start',
    gap: "0 20px",
    width: { xs: "100%", md: "calc(100% - 20px)" },
    p: { xs: "16px 10px", sm: "16px 20px", md: "10px 0", lg: "10px 20px" },
    boxSizing: "border-box",
    flexWrap: "wrap",
    // background: 'aqua'
  },
  formBox: {
    width: { xs: "100%", md: 'calc(50% - 10px)' }, height: '112px',maxWidth: "800px",
  },
  fieldsWrapper: (isDrawer) => ({
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "end",
    maxWidth: "800px",
    ml: 0,
    mr: 0,
  }),
  label: (isDrawer) => ({
    width: "100%",
    fontSize: { xs: "14px", lg: "16px" },
    textAlign: "start",
    marginRight: "10px",
  }),
  textField: {
    width: {
      xs: "100%",
      md: "100%",
    },
    maxWidth: "800px",
    textAlign: "end",
    // height: '80px'
  },
  btnContainer: {
    mt: 3,
    display: "flex",
    gap: "15px",
    justifyContent: "end",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
