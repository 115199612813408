import { Box, FormControl, OutlinedInput, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { formikValidation, handleError } from "../../../hooks/globalFunction";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { forgetPassword } from "../../../services/auth";
import { IMAGES } from "../../../theme";
import CustomButton from "../../CustomButton";
import ValidationError from "../../ValidationError";
import { Style } from "../style";

const ForgetPasswordForm = () => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { email } = values;
      await forgetPassword({
        email,
      })
        .then((res) => {
          resetForm();
          toast.success(
            "Reset password link has been sent to your provided email!"
          );
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(handleError(values, err?.response?.data));
        });
    },
  });

  return (
    <Box sx={[Style.formSection, Style.columnContainer]}>
      {width < 500 && (
        <Box sx={Style.logoContainer}>
          <Box component={"img"} src={IMAGES.logo} sx={Style.logo} />
        </Box>
      )}
      <Box
        component="form"
        sx={Style.form}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Typography sx={Style.heading}>Forget Password!</Typography>
        <Typography sx={Style.subHeading}>
          Reset your password and regain access to your account
        </Typography>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            type={"text"}
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.email && formik?.touched?.email && (
            <ValidationError error={formik?.errors?.email} />
          )}
        </FormControl>
        <CustomButton
          buttonText={"SEND EMAIL"}
          sx={{ width: "50%" }}
          type={"submit"}
          loading={loading}
          disable={formikValidation(formik)}
        />
        <Typography sx={Style.redirectText}>
          Do you remember your password?{" "}
          <Box
            onClick={() => navigate("/signin")}
            component="span"
            sx={Style.redirect}
          >
            Sign In!
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgetPasswordForm;
