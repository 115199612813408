import { globalStyle } from "../../styles/globalStyle";
import { COLORS } from "../../theme";

export const Style = {
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pera: {
    ...globalStyle.headings,
    color: COLORS.primary,
    pt: "100px",
    px: "8px",
  },
};
