export const style = {
  wrapper: {
    maxWidth: "410px",
    p: "0px",
    borderRadius: "0px",
    // height: "418px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  header: {
    height: "70px",
    backgroundColor: "#4A545A",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clossIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "10px",
    fontSize: "20px",
  },
  headerText: { fontSize: "32px", color: "#616466", padding: "10px 0px" },
  bottomText: { color: "#00000087", fontSize: "14px" },
  startQuizButton: {
    height: "65px",
    backgroundColor: "#10194B",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px",
    color: "#ffff",
  },
};
