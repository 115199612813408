import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomButton,
  DeleteModal,
  Loader,
  NoRecordFound,
  PreviewCards,
  UploadDocumentModal,
} from "../../../components";
import { handleError } from "../../../hooks/globalFunction";
import {
  deleteDocuments,
  getDocuments,
  getDocumentsById,
} from "../../../services/myLearnings";
import { selectdrawer } from "../../../store/slice/drawer";
import {
  deleteDocumentAction,
  selectUser,
  updateDocumentList,
  updateUserData,
} from "../../../store/slice/user";
import {
  addSummaryAction,
  addKeyPointsAction,
  selectedDocumentAction,
} from "../../../store/slice/summaryAndKeyPoints";
import { IMAGES } from "../../../theme";
import CompanyWrapper from "../../companies/summary/CompanyWrapper";
import { Style } from "../style";

const TrainingDocuments = () => {
  const state = useLocation()?.state;
  const data = state?.data || {};
  const {
    access,
    documents,
    isSuperAdmin,
    isAdmin,
    company_id,
    isUser,
    member_id,
  } = useSelector(selectUser);

  const navigate = useNavigate();
  const { id, departId } = useParams();

  const headingData = isSuperAdmin
    ? [
      {
        name: `Companies/`,
        handleNavigate: () => navigate(`/trainings`),
      },
      {
        name: `${state?.departData?.name}/Departments/`,
        handleNavigate: () =>
          navigate(`/trainings/company/${id}`, {
            state: { data: state?.departData },
          }),
      },
      { name: `${data?.name}/Documents` },
    ]
    : (isAdmin || isUser) && [
      {
        name: `Departments/`,
        handleNavigate: () =>
          isAdmin ? navigate(`/trainings/`) : navigate(`/my-learning/`),
      },
      { name: `${data?.name}/Documents` },
    ];
  const { drawer } = useSelector(selectdrawer);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState(false);

  const openDrawer = (val, index) => {
    setOpen(val);
    setEditItem({ file: documentsData?.results[index], index: index, ...data });
  };

  const onClose = () => {
    setOpenDeleteModal(false);
  };

  const [documentsData, setDocumentsData] = useState(documents);
  useEffect(() => {
    setDocumentsData(documents);
  }, [documents]);
  const dispatch = useDispatch();

  const documentOnScroll = async () => {
    let page = documents?.next?.split("/api/")?.[1];
    try {
      if (!!page) {
        const res = await getDocuments(
          dispatch,
          navigate,
          access,
          departId,
          page
        );
        dispatch(updateDocumentList(res?.data));
      } else setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const deleteDocument = async () => {
    try {
      setLoading(true);
      await deleteDocuments(dispatch, navigate, openDeleteModal, access);
      toast.success("Document deleted successfully");
      dispatch(deleteDocumentAction(openDeleteModal));
      setDocumentsData((prev) => {
        return {
          ...prev,
          results: prev?.results?.filter((item) => item?.id != openDeleteModal),
        };
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (err) {
      toast.error(
        handleError({ department_id: openDeleteModal }, err?.response?.data)
      );
      setLoading(false);
    }
  };
  const documentGet = async () => {
    setGetLoading(true);
    if (!!departId && documents?.id != departId) {
      const res = isUser
        ? await getDocumentsById(
          dispatch,
          navigate,
          access,
          member_id,
          departId
        )
        : await getDocuments(dispatch, navigate, access, departId);
      let documents = res?.data;
      documents.id = departId;
      dispatch(updateUserData({ documents }));
      setDocumentsData(res?.data);
    }
    setGetLoading(false);
  };
  useEffect(() => {
    documentGet();
  }, [id || company_id]);

  useEffect(() => {
    dispatch(addSummaryAction({}));
    dispatch(addKeyPointsAction({}));
  }, []);
  return (
    <CompanyWrapper
      headingData={headingData}
      topBannerIcon={IMAGES.training}
      topBannerHeading={ isUser ? "My Learning" : "Trainings"}
      handleBack={() =>
        isSuperAdmin
          ? navigate(`/trainings/company/${id}`, {
            state: { data: state?.departData },
          })
          : isAdmin
            ? navigate(`/trainings/`)
            : navigate(`/my-learning/`)
      }
    >
      {isAdmin && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <CustomButton
            buttonText="Upload Document"
            onClick={() => {
              setOpen(true);
            }}
          />
        </Box>
      )}
      <>
        {getLoading ? (
          <Loader
            style={{
              height: "unset",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          />
        ) : !documentsData?.results?.length ? (
          <NoRecordFound />
        ) : (
          <Box
          //  sx={{maxWidth: { xs: 'calc( 100vw - 40px )', sm: drawer ? 'calc( 100vw - 282px )' : 'calc( 100vw - 0px )' }}}
           >
            <InfiniteScroll
              dataLength={!!documentsData && documentsData?.results?.length}
              next={documentOnScroll}
              hasMore={!!documentsData?.next}
              loader={
                <Box sx={{ textAlign: "center", marginY: 5 }}>
                  <CircularProgress size={50} color="secondary" />
                </Box>
              }
              style={{ overflow: "hidden", display: 'flex', justifyContent: 'center !important' }}
            >
              <Grid spacing={2} sx={Style.cardWrapper(drawer)}>
                {documentsData?.results?.map((val, index) => (
                  <PreviewCards
                    key={index}
                    data={val}
                    handleClick={(event) => {
                      if (event.target.id !== 'no_change') {
                        dispatch(selectedDocumentAction(val));
                        navigate(
                          isSuperAdmin
                            ? `/trainings/company/${id}/department/${departId}/document/${val?.id}/summary`
                            : isAdmin
                              ? `/trainings/department/${departId}/document/${val?.id}/summary`
                              : `/my-learning/department/${departId}/document/${val?.id}/summary`,
                          {
                            state: {
                              data: data,
                              val: val,
                              departData: state?.departData,
                            },
                          }
                        );
                      }
                    }}
                    isDownload={true}
                    isDelete={isAdmin}
                    isEdit={isAdmin}
                    deleteIconClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(val?.id);
                    }}
                    editIconClick={() => openDrawer(val, index)}
                  />
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        )}
        <UploadDocumentModal
          open={open}
          setOpen={setOpen}
          selectedDepartment={{ ...data }}
          editItem={editItem}
          setEditItem={setEditItem}
        />
        <DeleteModal
          onConfirm={deleteDocument}
          onClose={onClose}
          open={!!openDeleteModal}
          setOpen={setOpenDeleteModal}
          loading={loading}
        />
      </>
    </CompanyWrapper>
  );
};
export default TrainingDocuments;
