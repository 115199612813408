import { Box } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaRegStar } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AdminQuizCard,
  Loader,
  NoRecordFound,
  ReuseModal,
  StartQuizModal,
  UserQuizCard,
} from "../../../components";
import { handleError } from "../../../hooks/globalFunction";
import { deleteQuestion } from "../../../services/quiz";
import { allQuizzez, deleteQuizzAction } from "../../../store/slice/quizzez";
import { selectUser } from "../../../store/slice/user";
import { Style } from "../style";

const QuizSection = ({ loader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, docId, departId } = useParams();
  const location = useLocation();
  const state = location?.state;
  const [deleteloader, setDeleteLoader] = useState(false);
  const [quizId, setQuizId] = useState(false);
  const { access, isUser, isSuperAdmin, isAdmin } = useSelector(selectUser);
  const { quizzezList } = useSelector(allQuizzez);
  const [selectedItem, setSelectedItem] = useState(false);

  const onConfirm = () => {
    navigate(
      `/my-learning/department/${departId}/document/${docId}/attemptQuiz`,
      {
        state: { ...state, quizData: selectedItem },
      }
    );
    setSelectedItem(false);
  };

  const deleteQuizData = async () => {
    try {
      setDeleteLoader(true);
      await deleteQuestion(dispatch, navigate, quizId, access);
      dispatch(deleteQuizzAction(quizId));
      setQuizId(false);
      toast.success("Quiz deleted Successfully!");
    } catch (err) {
      toast.error(handleError({}, err?.response?.data));
    } finally {
      setDeleteLoader(false);
    }
  };

  const handleClick = (item) => {
    if (isUser) {
      setSelectedItem(item);
    } else if (isSuperAdmin) {
      navigate(
        `/trainings/company/${id}/department/${departId}/document/${docId}/uploadQuiz`,
        {
          state: { ...state, quizData: item },
        }
      );
    } else {
      navigate(
        `/trainings/department/${departId}/document/${docId}/uploadQuiz`,
        {
          state: { ...state, quizData: item },
        }
      );
    }
  };

  const onResultClick = (item) => {
    if (isAdmin) {
      navigate(
        `/trainings/department/${departId}/document/${docId}/quizResult`,
        {
          state: { ...state, quizData: item },
        }
      );
    } else if (isSuperAdmin) {
      navigate(
        `/trainings/company/${id}/department/${departId}/document/${docId}/quizResult`,
        {
          state: { ...state, quizData: item },
        }
      );
    }
  };

  if (loader) return <Loader style={{ width: "100%", height: "auto" }} />;
  return (
    <>
      {!quizzezList?.length ? (
        <NoRecordFound />
      ) : (
        <Box sx={{ ...Style.customCardWrapper, alignContent: "start" }}>
          {quizzezList?.map((item, index) => {
            const { score, result_status, upload_status } = item;
            return isUser ? (
              <UserQuizCard
                onClick={() => handleClick({ ...item, index })}
                heading={`Quiz ${index + 1}`}
                subHeading={
                  score === null ? "Not Attempted" : `${Math.floor(score)}%`
                }
                score={score}
                iconClick={() => handleClick({ ...item, index })}
                key={index}
                topRightIcon={
                  score === null ? null : result_status == "Pass" ? (
                    <FaRegStar color="goldenrod" size={25} />
                  ) : (
                    <MdOutlineRefresh color="red" size={25} />
                  )
                }
                sx={{
                  width: "190px",
                  height: "150px",
                }}
                headingStyle={{
                  color:
                    score === null
                      ? "gray"
                      : result_status == "Pass"
                      ? "green"
                      : "red",
                  fontSize: 30,
                }}
                subHeadingStyle={{
                  color:
                    score === null
                      ? "gray"
                      : result_status == "Pass"
                      ? "green"
                      : "red",
                  fontWeight: "normal",
                  fontSize: score === null ? 20 : 45,
                }}
              />
            ) : (
              <AdminQuizCard
                onClick={() => handleClick({ ...item, index, upload_status })}
                onResultClick={() =>
                  onResultClick({ ...item, index, upload_status })
                }
                heading={`Quiz ${index + 1}`}
                uploadStatus={upload_status}
                subHeading={upload_status ? "Uploaded" : "Not Uploaded"}
                key={index}
                onDeleteClick={() => setQuizId(item?.id)}
                subHeadingStyle={{
                  color: upload_status ? "green" : "#9B870C",
                }}
              />
            );
          })}
          <StartQuizModal
            open={selectedItem}
            setOpen={setSelectedItem}
            onConfirm={onConfirm}
          />
          <ReuseModal
            title={"Are you sure you want to delete quiz ?"}
            open={!!quizId}
            setOpen={setQuizId}
            onConfirm={deleteQuizData}
            loader={deleteloader}
          />
        </Box>
      )}
    </>
  );
};

export default QuizSection;
