import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";
import { Logout } from "../../../hooks/globalFunction";

const LogoutModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <Typography sx={globalStyle.subHeading}>
        Do you want to Sign out ?
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <CustomButton
          onClick={() => setOpen(false)}
          color="secondary"
          buttonText={"NO"}
        />
        <CustomButton
          onClick={() => Logout(dispatch, navigate)}
          color="secondary"
          buttonText={"YES"}
        />
      </Box>
    </ModalWrapper>
  );
};
export default LogoutModal;
