import { Box, Typography } from "@mui/material";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";

const DiscardChangesModal = ({ open, setOpen, onConfirm }) => {
  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <Typography sx={globalStyle.subHeading}>
        Do you want to discard changes ?
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <CustomButton
          onClick={() => setOpen(false)}
          color="secondary"
          buttonText={"NO"}
        />
        <CustomButton
          onClick={onConfirm}
          color="secondary"
          buttonText={"YES"}
        />
      </Box>
    </ModalWrapper>
  );
};
export default DiscardChangesModal;
