import { BrowserRouter as Router } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { COLORS } from "./theme";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./route";
import { globalStyle } from "./styles/globalStyle";
import { useSelector } from "react-redux";
import { selectUser } from "./store/slice/user";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: COLORS.primary,
        main: COLORS.primary,
        dark: COLORS.primary,
      },
      secondary: {
        light: COLORS.secondary,
        main: COLORS.secondary,
        dark: COLORS.secondary,
      },
      black: {
        light: COLORS.black,
        main: COLORS.black,
        dark: COLORS.black,
      },
      white: {
        light: COLORS.white,
        main: COLORS.white,
        dark: COLORS.white,
      },
      golden: {
        light: COLORS.golden,
        main: COLORS.golden,
        dark: COLORS.golden,
      },
    },
  });
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Toaster position="top-right" reverseOrder={false} />
        <Box sx={globalStyle.routeWrapper}>
          <AppRoutes />
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default App;
