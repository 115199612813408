import React from "react";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const PerformanceChart = () => {
  const data = [
    {
      name: "Jan",
      amount: 1000,
    },
    {
      name: "Feb",
      amount: 2210,
    },
    {
      name: "Mar",
      amount: 3290,
    },
    {
      name: "Apr",
      amount: 4000,
    },
    {
      name: "May",
      amount: 5181,
    },
    {
      name: "Jun",
      amount: 6500,
    },
    {
      name: "July",
      amount: 7100,
    },
    {
      name: "Aug",
      amount: 8100,
    },
    {
      name: "Sept",
      amount: 1100,
    },
    {
      name: "Oct",
      amount: 3500,
    },
    {
      name: "Nov",
      amount: 4210,
    },
    {
      name: "Dec",
      amount: 5100,
    },
  ];
  return (
    <>
      <Typography variant="h4" fontWeight={"bold"} ml={2} mb={3}>
        Performance Chart
      </Typography>
      <Box maxWidth={900} height={350} justifyContent={"flex-start"} ml={-2}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          maxHeight={350}
          maxWidth={900}
        >
          <LineChart
            width={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Line type="linear" dataKey="amount" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default PerformanceChart;
