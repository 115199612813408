import AddIcon from "@mui/icons-material/Add";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CompanyCard,
  CustomButton,
  Loader,
  NoRecordFound,
} from "../../components";
import { getCompanies } from "../../services/companies";
import { selectdrawer } from "../../store/slice/drawer";
import { selectUser, updateUserData } from "../../store/slice/user";
import { globalStyle } from "../../styles/globalStyle";
import { IMAGES } from "../../theme";
import CompanyWrapper from "./summary/CompanyWrapper";
import { onScroll } from "./helper";
import InfiniteScroll from "react-infinite-scroll-component";

const Companies = () => {
  const navigate = useNavigate();
  const { access, company } = useSelector(selectUser);
  const [companies, setCompanies] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const FetchCompanies = async () => {
    if (!!company && !!Object?.values(company)?.length) {
      setCompanies(company);
      setLoading(false)
    }
    else {
      const result = await getCompanies(dispatch, navigate, access);
      dispatch(updateUserData({ company: result?.data }));
      setCompanies(result?.data);
      setLoading(false);
    };
  }

  useEffect(() => {
    setLoading(true)
    FetchCompanies();
  }, []);

  const { drawer } = useSelector(selectdrawer);

  return (
    <CompanyWrapper
      heading={"Your Companies"}
      topBannerIcon={IMAGES.companies}
      topBannerHeading={"Companies"}
      handleBack={() => navigate("/dashboard")}
    >
      <CustomButton
        sx={{ display: "flex", width: "fit-content", alignSelf: "flex-end" }}
        icon={<AddIcon />}
        buttonText={"Add Companies"}
        onClick={() => navigate("/companies/add")}
      />
      <Box sx={{ width: "100%" }}>
        {loading ? (
          <Loader
            style={{
              height: "unset",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          />
        ) :
          !!companies?.results?.length ? (
            <InfiniteScroll
              dataLength={!!companies?.result?.length}
              next={() =>
                onScroll(navigate, companies, access, dispatch, setCompanies)
              }
              hasMore={!!companies?.next}
              loader={
                <Box sx={{ textAlign: "center", marginY: 5 }}>
                  <CircularProgress size={50} color="secondary" />
                </Box>
              }
              style={{ overflow: "hidden", flexGrow: 1, width: "100%" }}
            >
              <Box
                sx={{
                  ...globalStyle.wrapper,
                  py: "0px",
                }}
              >
                <Box
                  sx={{
                    ...globalStyle.cardsWrapper(drawer),
                    alignItems: "flex-start",
                    width: "100%",
                    maxWidth: "calc(100vw - 24px)",
                  }}
                >
                  {companies?.results?.map((val, index) => (
                    <CompanyCard
                      key={index}
                      data={val}
                      onClick={() =>
                        navigate(`/company/${val?.id}`, { state: { data: val } })
                      }
                      setCompanies={setCompanies}
                    />
                  ))}
                </Box>
              </Box>
            </InfiniteScroll>
          ) : (
            <NoRecordFound />
          )}
      </Box>
    </CompanyWrapper>
  );
};

export default Companies;
