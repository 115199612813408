import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectdrawer } from "../../store/slice/drawer";
import { globalStyle } from "../../styles/globalStyle";
import { IMAGES } from "../../theme";
import { Style } from "./style";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/Delete";
import { deleteCompany, getCompanyById } from "../../services/companies";
import toast from "react-hot-toast";
import { selectUser, updateUserData } from "../../store/slice/user";

const CompanyCard = ({
  data,
  onClick = () => { },
  sx = {},
  isEditeable = true,
  isDeleteHidden,
  setCompanies,
  deleteCompanyFunc,
}) => {
  const { access, company } = useSelector(selectUser);
  const { drawer } = useSelector(selectdrawer);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id, name, logo } = data || {};
  const defaultImageUrl = useMemo(() => {
    return IMAGES.companyLogo;
  }, []);


  const removeCompany = async () => {
    await deleteCompany(dispatch, navigate, id, access);
    setCompanies((prev) => {
      let data = {
        ...prev,
        results: prev?.results?.filter((val) => val.id !== id),
      };
      dispatch(updateUserData({ company: data }));
      return data;
    });
    setOpen(false);
    toast.success("Company Deleted Successful");
  };
  const onEditClick = async () => {
    let formData = {}
    const companyData = company?.results
    let ind = companyData?.findIndex((item) => item?.id == id)
    if (!companyData[ind]?.edit) {
      formData = await getCompanyById(dispatch, navigate, access, id);
      formData.data.edit = true
      dispatch(updateUserData({company: {results: [...companyData?.slice(0, ind), formData?.data, ...companyData?.slice(ind + 1)]}}))
    }
    else formData.data = companyData[ind]
    navigate(`/company/update/${id}`, { state: { data: formData?.data } });
  };

  return (
    <>
      <Box sx={{ ...Style.card(drawer), ...sx }}>
        <Box
          onClick={onClick}
          component={"img"}
          src={!logo ? 
            // IMAGES.companyLogo
          // "https://dashboard.traino.ai/static/media/companyLogo.a93342bce6d7e3d2e898.png"
          // "https://traino-ai-api.vercel.app/static/media/companyLogo.a93342bce6d7e3d2e898.png"
          defaultImageUrl
          : 
          logo
          }
          sx={Style.img}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Tooltip title={name}>
          <Typography sx={globalStyle.headings} onClick={onClick}>
            {name}
          </Typography>
          </Tooltip>
          <Box sx={Style.btnContainer}>
            {!isDeleteHidden && (
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpen(true)}
              />
            )}
            {isEditeable && (
              <EditIcon style={{ cursor: "pointer" }} onClick={onEditClick} />
            )}
          </Box>
        </Box>
      </Box>
      <DeleteModal
        onConfirm={deleteCompanyFunc || removeCompany}
        onClose={() => setOpen(false)}
        open={open}
        setOpen={setOpen}
        loading={loading}
      />
    </>
  );
};

export default CompanyCard;
