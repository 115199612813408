import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomButton, TopBanner } from "../../../components";
import Layout from "../../../layout/MainLayout";
import { selectUser } from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import { tabsArray } from "../../training/helper";
import { selectdrawer } from "../../../store/slice/drawer";

const CompanyWrapper = ({
  children,
  heading,
  headingData,
  headingNavigation,
  topBannerIcon,
  topBannerHeading,
  isTabs,
  handleBack,
}) => {
  const navigate = useNavigate();
  const { drawer } = useSelector(selectdrawer);
  const { id, departId, docId, section } = useParams();
  const { isSuperAdmin, isAdmin } = useSelector(selectUser);
  const location = useLocation();
  // const section = location?.search?.split("?=")[1];
  const state = location?.state;
  const [tab, setActiveTab] = useState(location?.search?.split("?=")[1]);
  const tabs = tabsArray({
    id,
    departId,
    docId,
    navigate,
    isSuperAdmin,
    isAdmin,
    state,
  });
  const isTabShown =
    isTabs &&
    section != "uploadQuiz" &&
    section != "quiz" &&
    section != "attemptQuiz" &&
    section != "quizResult";

  useEffect(() => {
    setActiveTab(section);
  }, [location]);

  const isActiveCondition = (val) => {
    return (
      val?.tabName?.toLowerCase()?.replace(" ", "") ===
      tab?.toLowerCase()?.replace(" ", "")
    );
  };

  return (
    <Layout>
      <TopBanner Icon={topBannerIcon} text={topBannerHeading} />
      <Box sx={{ height: 50 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={globalStyle.HeroBanner}>
          {/* {section != "uploadQuiz" && ( */}
          <Box
            display={"flex"}
            component={"div"}
            onClick={() => {
              let ind = tabs?.findIndex((val) => isActiveCondition(val));
              if (isTabs && ind != -1) {
                tabs[ind].handleBack();
              } else if (handleBack) handleBack();
              else navigate(-1);
            }}
          >
            <ArrowBackIcon sx={globalStyle.arrowback} />
          </Box>
          {/* )} */}
          <Box sx={globalStyle.tabsMain(isTabs, drawer)}>
            {isTabShown ? (
              <Box sx={globalStyle.tabsContainer}>
                {tabs.map((val, index) => (
                  <CustomButton
                    key={index}
                    sx={globalStyle.tabs(isActiveCondition(val))}
                    typSx={globalStyle.tabsTyp}
                    buttonText={val.tabName}
                    onClick={val.func}
                  />
                ))}
              </Box>
            ) : (
              <Typography
                onClick={headingNavigation && headingNavigation}
                sx={heading ? globalStyle.headings : globalStyle.headingMain}
              >
                {heading
                  ? heading
                  : headingData &&
                  headingData?.map((item, index) => (
                    <Typography
                      key={index}
                      component={"span"}
                      sx={{
                        ...globalStyle.headings,
                        cursor:
                          index !== headingData?.length - 1
                            ? "pointer"
                            : "default",
                      }}
                      onClick={item?.handleNavigate}
                    >
                      {item?.name}
                    </Typography>
                  ))}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={globalStyle.divider} />
      <Box sx={globalStyle.wrapper}>{children}</Box>
    </Layout>
  );
};

export default CompanyWrapper;
