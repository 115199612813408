export const COLORS = {
  primary: "#10194B",
  secondary: "#10194B",
  white: "#fff",
  black: "#1E2324",
  grey: "#B4B6B6",
  golden: "#daa520",
  lightGolden: "#efcc77",
  red: "#d30000",
  green: "#83D475",
};
