import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { allQuizzez } from "../../store/slice/quizzez";
import CustomButton from "../CustomButton";
import { Style } from "./style";

const AddSummaryKeyPoints = ({
  paraText,
  handleClick,
  loading,
  quizLoader,
}) => {
  const { quizzezList } = useSelector(allQuizzez);
  const { departId, docId, section } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const handleNavigate = () => {
    navigate(`/trainings/department/${departId}/document/${docId}/quiz`, {
      state,
    });
  };

  return (
    <Box sx={Style.wrapper}>
      {section == "quizzes" && (
        <CustomButton
          sx={{ alignSelf: "flex-end" }}
          onClick={handleNavigate}
          disable={!quizzezList.length}
          loading={quizLoader}
          buttonText={`Uploaded Quizzes: ${quizzezList.length}`}
        />
      )}
      <Box sx={Style.contentContainer}>
        <Typography sx={Style.paraText}>{paraText}</Typography>
        {loading ? (
          <Box>
            <CircularProgress size={30} />
          </Box>
        ) : (
          <Box onClick={handleClick}>
            <AddCircleIcon style={Style.addIcon} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddSummaryKeyPoints;
