import { Box, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { globalStyle } from "../../../styles/globalStyle";
import ModalWrapper from "../../ModalWrapper";

const ViewUsers = ({ open, setOpen, data }) => {
  let tempData = !!data?.length && [...data]
  if (!!tempData?.length)
    tempData.unshift({ id: "ID", name: "NAME" })
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <ModalWrapper sx={{ borderRadius: "15px" }} open={!!open} setOpen={setOpen} onClose={handleClose} isCloseAble={true} crossIcon={true}>
      <TableContainer sx={{ height: "300px", padding:"0 !important" }}>
        <Table sx={{ padding:"0 !important"}}>
          <TableBody>
            {!!tempData?.length ?
              tempData?.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ border: '1px solid #a7a7a7', borderBottom: "2px solid #a7a7a7" }}
                  >
                    <TableCell sx={{fontSize:"17px", fontWeight: "600", color: index ? '#656565' : 'black', borderRight: '1px solid #a7a7a7' }}>
                      {!index ? 'NO' : index}
                    </TableCell>

                    <TableCell sx={{fontSize:"17px", fontWeight: "600", color: item.name === "NAME" ? 'black' : '#656565' }}>
                      { !index ? "Name" : `${item?.first_name} ${item?.last_name}`}
                    </TableCell>
                    {/* <TableCell sx={{fontSize:"17px", fontWeight: "600", color: item.name === "NAME" ? 'black' : '#656565' }}>
                      { !index ? "Email" : `${item?.email}`}
                    </TableCell> */}
                  </TableRow>
                )
              }
              ) :
              <Box sx={{ ...globalStyle.headingMain, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                <Typography sx={{ ...globalStyle.headingMain, color: 'black !important', width: '280px', textAlign: 'center', fontSize: "22px", fontWeight: "700", display: 'flex', justifyContent: 'center' }}>No User Assigned</Typography>
              </Box>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </ModalWrapper>
  );
};
export default ViewUsers;
