import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  CustomButton,
  DiscardChangesModal,
  ValidationError,
  CustomPhone,
} from "../../../components";
import CreateableSelect from "../../../components/SelctMenu/createableSelect";
import { selectdrawer } from "../../../store/slice/drawer";
import {
  addTeamMember,
  selectUser,
  updateTeamList,
  updateUserData,
} from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import { createUser, updateUser } from "./helper";
import { Style } from "./style";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { formikValidation } from "../../../hooks/globalFunction";

const CreateUserForm = ({ userRole, setUserRole }) => {
  const state = useLocation()?.state;
  const data = state?.val || {};
  const { id, user_id } = useParams();
  const [isDisable, setIsDisable] = useState(true);
  const [phone, setPhone] = useState(data?.phone || "");
  const companyId = id;
  const isEdit = user_id;
  const disabledStyle = {
    background: isEdit ? "#e7e7e7 !important" : "#ffff !important",
    cursor: isEdit ? "not-allowed !important" : "unset !important",
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    access,
    isAdmin,
    isSuperAdmin,
    company_id,
    departments,
    user,
    admin,
  } = useSelector(selectUser);
  const teams = userRole == "User" ? user : admin;
  const { drawer } = useSelector(selectdrawer);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const createUserSchema = isEdit
    ? {}
    : {
      email: Yup.string().email().required("Required!"),
      password: Yup.string()
        .required("Required!")
        .matches(
          /^(?=.*\d)^(?=.{8,16}$)(?=.*[`~!@#$%^&*()\-\+_=\{\}\[\]|\\:;"'<>,.?\/])/,
          "Password must contain at least one digit, one special character and length should be between 8 to 16 characters"
        ),
    };
  const tempDepart =
    data?.departments_names &&
    departments?.results?.filter((item) =>
      data?.departments_names?.includes(item?.name)
    );
  const formik = useFormik({
    initialValues:
      (!!data?.role ? data?.role === "User" : userRole === "User")
        ? {
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          role: data?.role || (isSuperAdmin ? "Admin" : "User"),
          email: data?.email || "",
          password: !!data?.id ? "*********" : "",
        }
        : {
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          email: data?.email || "",
          password: !!data?.id ? "*********" : "",
          role: data?.role || (isSuperAdmin ? "Admin" : "User"),
          department: !!tempDepart?.length && tempDepart?.map((item) => ({
            label: item?.name,
            value: item?.id,
          })),
        },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Required!"),
      last_name: Yup.string().required("Required!"),
      department:
        userRole == "User" && Yup.array().required("Required!").min(1),
      ...createUserSchema,
    }),
    onSubmit: async (values, { resetForm }) => {
      let tempDepartments = [];
      if (!!values?.department?.length)
        values?.department?.map((item) => {
          tempDepartments?.push(item?.value);
        });
      const { department, ...others } = values;
      const valuesData = {
        ...others,
        department_ids: tempDepartments || null,
        phone: phone,
      };
      let updateData = {
        first_name: values.first_name,
        last_name: values.last_name,
        department_ids: tempDepartments,
        company: companyId || company_id,
      };
      if (!!phone) updateData.phone = phone;
      setLoading(true);
      if (!!data?.user_update_key || !!data?.admin_update_key || data?.id) {
        const res = await updateUser(
          updateData,
          access,
          data?.user_update_key || data?.admin_update_key || data?.id,
          dispatch,
          navigate
        );
        if (res?.id) {
          resetForm();
          let tempData = { ...res };
          Object?.entries(res)?.map((item) => {
            if (
              item[0] == "first_name" ||
              item[0] == "last_name" ||
              item[0] == "email"
            )
              return (tempData[
                `${userRole == "User" ? "members__" : "admin__"}${item[0]}`
              ] = item[1]);
          });

          let names = []
          tempData?.departments?.map((item) => names.push(item?.name))
          tempData.departments_names = names
          dispatch(
            updateTeamList({ role: userRole?.toLowerCase(), data: tempData })
          );
          department &&
            department?.map((item) => {
              let tempDepartments = [...departments?.results];
              let ind = tempDepartments?.findIndex(
                (item2) => item2.id == item?.value
              );
              let data = !!tempDepartments[ind]?.users?.length
                ? tempDepartments[ind]?.users?.includes(tempData?.id)
                  ? [...tempDepartments[ind]?.users]
                  : [...tempDepartments[ind]?.users, tempData?.id]
                : [tempData?.id];
              dispatch(updateUserData({ departments: { results: [...data] } }));
            });
          navigate(isSuperAdmin ? `/company/${companyId}` : `/my-teams/`);
        }
        setLoading(false);
      } else {
        const res = await createUser(
          companyId || company_id,
          valuesData,
          access,
          dispatch,
          navigate
        );
        if (res?.id) {
          resetForm();
          let tempData = { ...res };
          Object?.entries(res)?.map((item) => {
            if (
              item[0] == "first_name" ||
              item[0] == "last_name" ||
              item[0] == "email"
            )
              return (tempData[
                `${userRole == "User" ? "members__" : "admin__"}${item[0]}`
              ] = item[1]);
          });
          let condition =
            teams?.results?.length < 10 ||
            teams?.results?.length == teams?.count;
          let names = [];
          tempData?.departments?.map((item) => names.push(item?.name));
          tempData.departments_names = names;
          dispatch(
            addTeamMember({
              role: userRole?.toLowerCase(),
              data: tempData,
              countOnly: !condition,
            })
          );
          department &&
            department?.map((item) => {
              let tempDepartments = [...departments?.results];
              let ind = tempDepartments?.findIndex(
                (item2) => item2.id == item?.value
              );
              let data = !!tempDepartments[ind]?.users?.length
                ? tempDepartments[ind]?.users?.includes(tempData?.id)
                  ? [...tempDepartments[ind]?.users]
                  : [...tempDepartments[ind]?.users, tempData?.id]
                : [tempData?.id];
              dispatch(updateUserData({ departments: { results: [...data] } }));
            });
          navigate(isSuperAdmin ? `/company/${companyId}` : `/my-teams/`);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    setUserRole(formik?.values?.role);
  }, [formik?.values, departments]);

  useEffect(() => {
    if (!!departments?.id) {
      let tempData = departments?.results
        ?.filter((item) => data?.departments_names?.includes(item?.name))
        ?.map((item) => ({ label: item?.name, value: item?.id }));
      formik.setFieldValue("department", data?.departments_names && tempData);
    }
  }, [departments]);

  const formLeftSidedata = [
    {
      label: "First Name",
      field: (
        <TextField
          sx={Style.textField}
          id="outlined-basic"
          variant="outlined"
          value={formik.values.first_name}
          name="first_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      ),
      name: "first_name",
      isRequired: true,
    },
    {
      label: "Last Name",
      field: (
        <TextField
          sx={Style.textField}
          id="outlined-basic"
          variant="outlined"
          value={formik.values.last_name}
          name="last_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      ),
      isRequired: true,
      name: "last_name",
    },
    {
      label: "Email",
      field: (
        <TextField
          sx={{ ...Style.textField, ...disabledStyle }}
          id="outlined-basic"
          variant="outlined"
          value={formik.values.email}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isEdit}
        />
      ),
      name: "email",
      isRequired: true,
    },
    {
      label: "Phone",
      field: (
        <CustomPhone
          inputProps={{ ...Style.textField, ...disabledStyle }}
          country={"pk"}
          enableSearch={true}
          enableAreaCodes={true}
          disableSearchIcon={true}
          autocompleteSearch={true}
          enableClickOutside={true}
          defaultErrorMessage="Invalid Number"
          placeholder="Mobile Number"
          value={phone}
          countryCodeEditable={true}
          onChange={(no) => {
            setPhone(no?.length ? `+${no}` : no);
          }}
          onBlur={formik.handleBlur}
        />
      ),
      isRequired: false,
    },
    {
      label: "Password",
      field: (
        <FormControl sx={{ ...Style.textField, ...disabledStyle }}>
          <OutlinedInput
            sx={{ ...Style.textField, ...disabledStyle }}
            id="outlined-basic"
            disabled={isEdit}
            value={formik.values.password}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      ),
      name: "password",
      isRequired: true,
    },
    {
      label: "Add Role",
      field: (
        <Autocomplete
          onChange={(event, newValue) => {
            formik.setFieldValue("role", newValue);
          }}
          id="role"
          options={["User", "Admin"]}
          sx={{ ...Style.textField, ...disabledStyle }}
          renderInput={(params) => <TextField {...params} />}
          value={formik.values.role}
          name="role"
          disabled={!!user_id}
        />
      ),
      isRequired: true,
    },
    userRole == "User" && {
      label: "Department",
      field: <CreateableSelect formik={formik} />,
      isRequired: (!!data?.role ? data?.role === "User" : userRole === "User"),
      name: "department",
    },
  ].filter(Boolean);

  useEffect(() => {
    if (userRole == "Admin") {
      const { first_name, last_name, email, password } = formik?.values;
      const data = { first_name, last_name, email, password };
      const customFormik = { errors: formik?.errors, values: data };
      let check = true;
      Object?.entries(formik?.initialValues)?.map((item) => {
        if (
          !!data[item[0]] &&
          !!item[1] &&
          check &&
          item[1] != formik?.values[item[0]]
        ) {
          check = false;
        }
      });
      check && formikValidation(customFormik)
        ? setIsDisable(true)
        : setIsDisable(false);
    } else {
      const { first_name, last_name, email, department, password } =
        formik?.values;
      const data = {
        first_name,
        last_name,
        email,
        department,
        password,
      };
      const customFormik = { errors: formik?.errors, values: data };
      let check = true;
      Object?.entries(formik?.initialValues)?.map((item) => {
        if (
          !!data[item[0]] &&
          !!item[1] &&
          check &&
          item[1] != formik?.values[item[0]]
        ) {
          check = false;
        }
      });
      setIsDisable(check || formikValidation(customFormik))
    }
  }, [formik]);

  const checkDisable = () => {
    const labels = !!formik?.values?.department?.length && formik?.values?.department?.map((item) => item.label);
    return (
      formik.values.first_name === data?.first_name &&
      formik.values.last_name === data?.last_name &&
      phone === data?.phone &&
      JSON.stringify(data?.departments_names) === JSON.stringify(labels)
    );
  };

  useEffect(() => {
    if (!data?.id) {
      const { first_name, last_name, email, password } = formik?.values;
      const data = { first_name, last_name };
      const customFormik = { errors: formik?.errors, values: data };
      setIsDisable(checkDisable() || formikValidation(customFormik));
    }
  }, [data, formik, phone]);
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={globalStyle.subHeading}>
        Admin / User Information
      </Typography>
      <Box sx={Style.formWrapper}>
        <Box component={"form"} sx={Style.form} onSubmit={formik.handleSubmit}>
          <Box sx={Style.formContainer}>
            {/* left hand */}
            <Box sx={Style.formSection}>
              {formLeftSidedata.map(
                ({ label, field, name, isRequired }, index) => {
                  return (
                    <Box sx={Style.createForm} key={index}>
                      <FormControlLabel
                        key={index}
                        sx={Style.fieldsWrapper(drawer)}
                        label={
                          <Typography sx={Style.label(drawer)} variant="body1">
                            {isRequired && (
                              <Box component={"span"} sx={{ color: "red" }}>
                                *
                              </Box>
                            )}
                            {label}:
                          </Typography>
                        }
                        control={field}
                      />
                      {formik?.errors?.[name] && formik.touched?.[name] && (
                        <ValidationError error={formik?.errors?.[name]} />
                      )}
                    </Box>
                  );
                }
              )}
            </Box>
            {/* left hand */}
          </Box>

          <Box sx={Style.btnContainer}>
            <CustomButton
              color="secondary"
              buttonText={user_id ? "Update" : "Create"}
              type="submit"
              loading={loading}
              disable={!!Object?.values(data)?.length && !!phone?.length ? (!!data?.phone && data?.phone == phone) && isDisable : isDisable}
            />
            <CustomButton
              color="secondary"
              buttonText={"Cancel"}
              onClick={() => setOpen(true)}
            />
          </Box>
        </Box>
      </Box>
      <DiscardChangesModal
        open={open}
        setOpen={setOpen}
        onConfirm={() => {
          navigate(-1);
        }}
      />
    </Box>
  );
};

export default CreateUserForm;
