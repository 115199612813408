import React from "react";
import Button from "@mui/material/Button";
import { Style } from "./style";
import { CircularProgress, Typography } from "@mui/material";
import { COLORS } from "../../theme";

const CustomButton = (props) => {
  const {
    buttonText,
    color,
    sx = {},
    typSx,
    icon,
    onClick = () => {},
    disable,
    loading,
    type,
    hoverSx,
  } = props;
  return (
    <Button
      disabled={disable || loading}
      onClick={onClick}
      variant="contained"
      color={color}
      type={type || "button"}
      sx={{
        ...Style.button,
        backgroundColor: (disable || loading) && "gainsboro !important",
        color: disable && `${COLORS.black} !important`,
        "&:hover": {
          ...hoverSx,
        },
        ...sx,
      }}
    >
      {!loading ? (
        !icon ? (
          <Typography sx={typSx}>{buttonText}</Typography>
        ) : (
          <>
            {icon}
            <Typography sx={typSx}>{buttonText}</Typography>
          </>
        )
      ) : (
        <CircularProgress size={30} />
      )}
    </Button>
  );
};

export default CustomButton;
