import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { handleError } from "../../../hooks/globalFunction";
import { changePassword, updateAdminData } from "../../../services/auth";
import { selectUser, updateUserData } from "../../../store/slice/user";
import CustomButton from "../../CustomButton";
import ValidationError from "../../ValidationError";
import { Style } from "../style";
import { useNavigate } from "react-router-dom";

const EditProfileForm = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { first_name, last_name, access } = useSelector(selectUser);
  const [showPassword, setShowPassword] = React.useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });

  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: first_name,
      lastName: last_name,
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      currentPassword: Yup.string(),
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords should be matched"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const {
        firstName,
        lastName,
        password,
        confirmPassword,
        currentPassword,
      } = values;

      const updateProfileData = {
        first_name: firstName,
        last_name: lastName,
      };
      const newPasswordData = {
        current_password: currentPassword,
        new_password: password,
        re_new_password: confirmPassword,
      };
      if (!(firstName === first_name && lastName === last_name)) {
        setLoading(true);
        await updateAdminData(dispatch, navigate, updateProfileData, access)
          .then((res) => {
            dispatch(
              updateUserData({
                ...res.data,
              })
            );
            toast.success("Profile updated successfully");
            resetForm();
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            toast.error(handleError(updateProfileData, err?.response?.data));
            setLoading(false);
          });
      }
      if (!!currentPassword && !!password && !!confirmPassword) {
        setLoading(true);
        await changePassword(dispatch, navigate, newPasswordData, access)
          .then((res) => {
            toast.success("password changed successfully");
            resetForm();
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            toast.error(handleError(newPasswordData, err?.response?.data));
            setLoading(false);
          });
      }
    },
  });

  const hasErrors = !!Object.values(formik.errors || []).length;
  const isNameChanged =
    formik.values.firstName === first_name &&
    formik.values.lastName === last_name;
  const isPasswordChanged =
    formik.values.currentPassword &&
    formik.values.password &&
    formik.values.confirmPassword;

  const disabled =
    hasErrors || (!hasErrors && isNameChanged && !isPasswordChanged);

  return (
    <Box sx={{ ...Style.formSection, width: "100%", px: 0 }}>
      <Box
        component="form"
        sx={Style.form}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Typography sx={Style.subHeading}>Edit Profile</Typography>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <OutlinedInput
            id="firstName"
            type={"text"}
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.firstName && formik.touched.firstName && (
            <ValidationError error={formik?.errors?.firstName} />
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <OutlinedInput
            id="lastName"
            type={"text"}
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.lastName && formik.touched.lastName && (
            <ValidationError error={formik?.errors?.lastName} />
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="currPassword">Current Password</InputLabel>
          <OutlinedInput
            id="currPassword"
            type={showPassword.currentPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowPassword((prevState) => ({
                      ...prevState,
                      currentPassword: !prevState.currentPassword,
                    }))
                  }
                  edge="end"
                >
                  {showPassword.currentPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Current Password"
            name="currentPassword"
            value={formik.values.c}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.currentPassword &&
            formik.touched.currentPassword && (
              <ValidationError error={formik?.errors?.currentPassword} />
            )}
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword.password ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowPassword((prevState) => ({
                      ...prevState,
                      password: !prevState.password,
                    }))
                  }
                  edge="end"
                >
                  {showPassword.password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.password && formik.touched.password && (
            <ValidationError error={formik?.errors?.password} />
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="password">Confirm Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword.confirmPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowPassword((prevState) => ({
                      ...prevState,
                      confirmPassword: !prevState.confirmPassword,
                    }))
                  }
                  edge="end"
                >
                  {showPassword.confirmPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.confirmPassword &&
            formik.touched.confirmPassword && (
              <ValidationError error={formik?.errors?.confirmPassword} />
            )}
        </FormControl>
        <CustomButton
          sx={{ width: "50%" }}
          buttonText={"UPDATE"}
          type={"submit"}
          loading={loading}
          disable={disabled}
        />
      </Box>
    </Box>
  );
};
export default EditProfileForm;
