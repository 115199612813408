import { Box, Typography } from "@mui/material";
import React from "react";
import Typewriter from "typewriter-effect";
import { Style } from "./style";

const LeftImageSection = () => {
  const onInit = (typewriter) => {
    typewriter
      .typeString("A place ")
      .pauseFor(500)
      .typeString("where learning ")
      .pauseFor(500)
      .typeString("becomes easy")
      .pauseFor(500)
      .start();
  };
  return (
    <Box sx={Style.main}>
      <Box sx={Style.content}>
        <Typography sx={Style.welcomeHeading}>Welcome To</Typography>
        <Typography sx={Style.appName}>Traino.ai</Typography>
        <Box sx={Style.slogan}>
          <Typewriter onInit={onInit} options={{ loop: true }} />
        </Box>
      </Box>
    </Box>
  );
};

export default LeftImageSection;
