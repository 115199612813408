import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../../../hooks/globalFunction";
import { createDepartments } from "../../../services/departments";
import { addDepartment, selectUser } from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";
import { Style } from "./style";
import { useNavigate } from "react-router-dom";

const AddDepartmentModal = ({ open, setOpen, setDepartmentData }) => {
  const { access, company_id } = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [departmentName, setDepartmentName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!!departmentName.length) {
      const data = {
        name: departmentName,
        company: company_id,
      };
      try {
        setLoading(true);
        const departments = await createDepartments(
          dispatch,
          navigate,
          data,
          access
        );
        dispatch(addDepartment(departments.data));
        setDepartmentData((prev) => [...prev, departments?.data]);
        toast.success("Department created successfully");
        setDepartmentName("");
        setLoading(false);
        setOpen(false);
      } catch (err) {
        toast.error(handleError(data, err?.response?.data));
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDepartmentName("");
  };

  return (
    <ModalWrapper open={!!open} setOpen={setOpen}>
      <Box component={"form"} sx={Style.form} onSubmit={handleSubmit}>
        <Box sx={Style.header}>
          <Typography sx={globalStyle.headings}>Add Department</Typography>
        </Box>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="department">Department Name</InputLabel>
          <OutlinedInput
            value={departmentName}
            id="department"
            type={"text"}
            label="Department Name"
            onChange={(e) => setDepartmentName(e.target.value)}
          />
        </FormControl>
        <Box sx={Style.buttonsContainer}>
          <CustomButton
            color="secondary"
            buttonText={"Cancel"}
            onClick={handleClose}
          />
          <CustomButton
            loading={loading}
            disable={!departmentName.length}
            color="secondary"
            buttonText={"Save"}
            type="submitt"
          />
        </Box>
      </Box>
    </ModalWrapper>
  );
};
export default AddDepartmentModal;
