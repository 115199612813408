import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins, getUsers } from "../../services/companies";
import { getTeamsList, selectUser } from "../../store/slice/user";
import DeleteModal from "../Modals/Delete";
import { handleDeleteTeam, handlePagination, handleSort } from "./helper";
import { Style } from "./style";

import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CustomTable = ({ isuser }) => {
  const dispatch = useDispatch();
  const state = useLocation()?.state;
  const [isItemSelected, setIsItemSelected] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setCompany] = useState({ loading: true });
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();
  const {
    access,
    company_id,
    isSuperAdmin,
    company_name,
    isAdmin,
    admin_id,
    user,
    admin,
    departments
  } = useSelector(selectUser);
  const isButtonVisible = !isuser;
  let teams = isuser ? { ...user } : { ...admin };
  const FetchCompany = async () => {
    setIsLoading(true)
    if (
      !teams?.id ||
      (!!id && teams?.id != id) ||
      (!!company_id && teams?.id != company_id)
    ) {
      let results = isuser
        ? await getUsers(dispatch, navigate, id || company_id, access)
        : await getAdmins(dispatch, navigate, company_id || id, access);
      results.data.id = id ? id : company_id;
      results.data.status = [{ pgNo: 0, sort: "id" }];
      results?.data?.results?.map((item2) =>
        Object?.entries(item2)?.map((item) => {
          if (
            item[0] == "first_name" ||
            item[0] == "last_name" ||
            item[0] == "email"
          )
            return (item2[(isuser ? "members__" : "admin__") + item[0]] =
              item[1]);
        })
      );
      setCompany(results?.data);
      dispatch(
        getTeamsList({ role: isuser ? "user" : "admin", data: results?.data })
      );
    } else setCompany(teams);
    setLoading(false);
    setTimeout(() => setIsLoading(false), 300)
  };
  useEffect(() => {
    FetchCompany();
  }, [isuser]);

  useEffect(() => {
    setIsLoading(true)
    teams.results = teams.results?.filter(
      (_, index) =>
        teams?.results?.length <= 10 ||
        (index > Number(pageNo) * 10 - 1 && index < (Number(pageNo) + 1) * 10)
    );
    setCompany(teams);
    setTimeout(() => setIsLoading(false), 300)
  }, [user, admin]);

  const handleEditClick = async (params) => {
    setIsLoading(true);
    const result = { data: { ...params.row } }
    if (result?.data) {
      let tempData = result?.data;
      tempData.role = isuser ? "User" : "Admin";
      navigate(
        isSuperAdmin
          ? `/company/${id}/update/${isuser ? `user` : `admin`}/${params?.row?.id
          }`
          : `/my-teams/update/${isuser ? `user` : `admin`}/${params?.row?.id}`,
        {
          state: { ...state, val: tempData },
        }
      );
    }
    setTimeout(() => setIsLoading(false), 300)
  };

  const columns = isButtonVisible
    ? [
      {
        field: `admin__first_name`,
        headerName: "First Name",
        width: 150,
        sortable: true,
      },
      {
        field: `admin__last_name`,
        headerName: "Last Name",
        width: 130,

        sortable: true,
      },
      {
        field: `admin__email`,
        headerName: "Email",
        width: 200,
        sortable: true,
      },
    ]
    : [
      {
        field: `members__first_name`,
        headerName: "First Name",
        width: 150,
        sortable: true,
      },
      {
        field: `members__last_name`,
        headerName: "Last Name",
        width: 130,

        sortable: true,
      },
      {
        field: `members__email`,
        headerName: "Email",
        width: 200,
        sortable: true,
      },
      {
        field: `departments_names`,
        headerName: "Departments",
        width: 200,
        sortable: true,
      }
    ]

  const isDeleteDisable = !!isItemSelected?.length;
  const rows = data?.results || []
  const formattedRows = rows.map((row) => ({
    ...row,
    departments_names: isuser ? row.departments_names.join(', ') : [],
  }));

  const CustomNoRowsOverlay = (load) => {
    if (load)
      return <Box sx={{width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>
    else
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          No rows
        </Box>)
  }

  return (
    <Box sx={Style.main(data?.results)}>
      <Box align="center" sx={Style.tittleContainer}>
        <Typography sx={Style.tittle}>{isuser ? "Users" : "Admins"}</Typography>
        <DeleteIcon
          style={Style.deleteIcon(isDeleteDisable)}
          onClick={() => isDeleteDisable && setOpen(true)}
        />
      </Box>
      <DataGrid
        sx={Style.tableContainer}
        rows={isuser ? formattedRows : rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        checkboxSelection={true}
        isRowSelectable={(params) => params.id !== admin_id}
        onCellClick={(params, event) => {
          if (event.target.type !== 'checkbox') {
            handleEditClick(params);
          } else {
            setIsItemSelected((prev) => [...prev, params.row.id])
          }
        }}
        loading={isLoading ? isLoading : data?.loading}
        slots={{
          noRowsOverlay: () => CustomNoRowsOverlay(isLoading ? isLoading : data?.loading),
        }}
        paginationMode="server"
        onPaginationModelChange={(newModel) => {
          if (teams) {
            handlePagination(
              navigate,
              newModel,
              access,
              dispatch,
              setIsLoading,
              isuser,
              id,
              company_id,
              data,
              sort,
              setPageNo
            );
          }
        }}
        rowCount={data?.count || 0}
        selectionModel={isItemSelected}
        sortingMode="server"
        onSortModelChange={(newModel) => {
          let teams = isuser ? user : admin;
          if (!!teams?.results?.length)
            handleSort(
              newModel,
              isuser,
              access,
              setIsLoading,
              data,
              setSort,
              company_id || id,
              pageNo,
              dispatch,
              isuser,
              teams,
              navigate
            );
        }}
      />
      <DeleteModal
        onConfirm={() =>
          handleDeleteTeam(
            setCompany,
            access,
            dispatch,
            isItemSelected,
            setIsItemSelected,
            setLoading,
            setOpen,
            isuser,
            admin_id,
            navigate,
            departments
          )
        }
        onClose={() => setOpen(false)}
        open={open}
        setOpen={setOpen}
        loading={loading}
      />
    </Box>
  );
};
export default CustomTable;
