import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import toast from "react-hot-toast";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../hooks/globalFunction";
import { addDocuments, updateDocuments } from "../../../services/myLearnings";
import {
  addDocument,
  selectUser,
  updateDocument,
} from "../../../store/slice/user";
import { globalStyle } from "../../../styles/globalStyle";
import { IMAGES } from "../../../theme";
import CustomButton from "../../CustomButton";
import SetReminder from "../../Dropdowns/SetReminder";
import ModalWrapper from "../../ModalWrapper";
import DepartmentSelect from "../../SelctMenu/departmentSelect";
import UserSelect from "../../SelctMenu/userSelect";
import { Style } from "./style";

const UploadDocumentModal = ({
  open,
  setOpen,
  selectedDepartment,
  editItem,
  setEditItem,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [reminderValue, setReminderValue] = useState("");
  const [department, setDepartment] = useState([selectedDepartment]);
  const [assignedDepartments, setAssignedDepartments] = useState([]);
  const { file, index, ...others } = editItem;
  const [files, setFile] = useState(null);
  const [edit, setEdit] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [inputName, setInputName] = useState("");
  const { documents, access } = useSelector(selectUser);
  const reminderDropdown = Boolean(reminder);
  const [user, setUser] = useState([]);
  const reminderoptions = {
    daily: "Daily",
    "3_days_a_week": "3 days in a Week",
    weekly: "Weekly",
    monthly: "Monthly",
  };

  const handleReminderClick = (event) => {
    setReminder(event.currentTarget);
  };
  const handleReminderClose = () => {
    setReminder(null);
  };
  const handleClick = (key) => {
    setReminderValue(key);
    setReminder(null);
  };

  const handleChange = (file) => {
    if (!editItem?.file) {
      setFileName(file?.name?.split(".pdf")[0]);
      setInputName(file?.name?.split(".pdf")[0]);
      setFile(file);
    }
  };

  useEffect(() => {
    setFileName(file?.name?.split(".pdf")[0]);
    setInputName(file?.name?.split(".pdf")[0]);
  }, [file]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      setFileName(inputName);
      setEdit(false);
    } else setEdit(true);
  };

  const handleSubmit = async () => {
    const departmentIds = assignedDepartments
      ?.filter((item) => !!item?.id)
      ?.map((item) => item.id);
    const payload = new FormData();
    let updateData = {};

    if (files) {
      payload.append("file", files);
      payload.append(
        "name",
        `${fileName != files?.name ? fileName : files?.name}.pdf`
      );
      updateData.file = files;
    }
    updateData.name = `${fileName != files?.name ? fileName : files?.name}.pdf`;
    if (!!reminderValue) {
      payload.append("schedule_frequency", reminderValue);
      updateData.schedule_frequency = reminderValue;
    }
    if (!!user && !user?.includes("all")) {
      payload.append("all", false);
      updateData.all = false;
      user?.map((item, index) => {
        payload.append(`assigned_users[${index}]`, item);
      });
      updateData[`user_ids`] = user;
    } else {
      payload.append("all", true);
      updateData.all = true;
    }
    departmentIds?.map((val, index) => {
      payload.append(`department_ids[${index}]`, val);
    });
    updateData[`department_ids`] = departmentIds;
    try {
      setLoader(true);
      if (editItem) {
        const tempData = await updateDocuments(
          dispatch,
          navigate,
          updateData,
          open?.id,
          access
        );
        const data = { ...tempData?.data };
        if (!!tempData?.data) dispatch(updateDocument({ data, index: index }));
        setEditItem(false);
      } else {
        const { data } = await addDocuments(
          dispatch,
          navigate,
          payload,
          access
        );
        let tempData = {
          file: files,
          name: fileName != files?.name ? fileName : files?.name,
          id: data?.created_documents[0],
        };
        const check = documents?.results?.findIndex(
          (item) => item?.name == files?.name
        );
        let isPresent = check + 1;
        if (!isPresent) dispatch(addDocument(tempData));
      }
      toast.success(
        `Document successfully ${!editItem ? "uploaded" : "updated"}`
      );
      handleClose();
    } catch (err) {
      toast.error(handleError({}, err?.response?.data));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    if (!edit) {
      setFile(null);
    }
    setEdit(false);
  };

  const handleClose = () => {
    setFile(null);
    setDepartment({ data: [selectedDepartment] });
    setOpen(false);
    setEditItem(false);
    setEdit(false);
    setUser([]);
    setLoader(false);
    setReminderValue("");
  };

  useEffect(() => {
    setDepartment({ data: [selectedDepartment] });
  }, [selectedDepartment]);

  useEffect(() => {
    if (!!editItem) {
      setDepartment({ data: [editItem] });
      setReminderValue(reminderoptions[editItem?.file?.schedule_frequency]);
    }
  }, [editItem]);

  const isFile = files || file;
  const isAssignAble =
    editItem && editItem?.file?.is_keypoints && editItem?.file?.is_summary;
  return (
    <ModalWrapper
      open={!!open}
      setOpen={setOpen}
      sx={Style.wrapper(isAssignAble)}
    >
      <Box sx={Style.header}>
        <Typography sx={globalStyle.headings}>
          {isAssignAble ? "Assign" : "Upload"} Document
        </Typography>
      </Box>
      <Box sx={Style.container(isAssignAble)}>
        {/* left section */}
        {isAssignAble && (
          <Box sx={Style.leftSide}>
            <Box sx={Style.leftSideChild}>
              <Typography
                sx={{
                  ...Style.header,
                  ...Style.uploadDocumnet,
                }}
              >
                Assign to
              </Typography>
              <DepartmentSelect
                setDepartment={setDepartment}
                department={department}
                selectedDepartment={selectedDepartment}
                editItem={editItem}
                assignedDepartments={assignedDepartments}
                setAssignedDepartments={setAssignedDepartments}
              />
              <UserSelect
                user={user}
                setUser={setUser}
                department={department}
                editItem={editItem}
              />
              <Button
                id="reminder"
                sx={Style.setReminderBtn}
                onClick={handleReminderClick}
              >
                <Box />
                <Typography>
                  {reminderoptions[reminderValue] ||
                    reminderValue ||
                    "Set reminder"}
                </Typography>
                <Box>
                  {!!reminderDropdown ? <FaChevronUp /> : <FaChevronDown />}
                </Box>
              </Button>
              <SetReminder
                id={"reminder"}
                handleClose={handleReminderClose}
                anchorEl={reminder}
                open={reminderDropdown}
                value={reminderValue}
                handleClick={handleClick}
                reminderoptions={reminderoptions}
              />
            </Box>
            <Box sx={{ ...Style.buttonsContainer, mt: { xs: 0 } }}>
              <CustomButton
                loading={loader}
                color="secondary"
                buttonText={!editItem ? "ADD" : "UPDATE"}
                onClick={handleSubmit}
                disable={
                  !!editItem
                    ? !department?.data?.length && !isFile
                    : !department?.data?.length || !isFile
                }
              />
              <CustomButton
                onClick={handleClose}
                color="secondary"
                buttonText={"CLOSE"}
              />
            </Box>
          </Box>
        )}
        {/* right section */}
        <Box sx={Style.rightSide(isAssignAble)}>
          <Box
            sx={{
              ...Style.rightSideChild,
              cursor: !!editItem?.file ? "not-allowed" : "pointer",
            }}
          >
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={["PDF"]}
              label="Upload or drop a file right here"
              hoverTitle="Drop document here!"
              required={true}
              disabled={!!editItem?.file}
            >
              <Box sx={Style.uploadSectionContainer(!!editItem?.file)}>
                <Box
                  sx={Style.uploadImage}
                  component={"img"}
                  src={IMAGES.uploadDocument}
                  alt="Upload Document"
                />
                <Typography sx={Style.text}>
                  {isFile ? "Your file " : "Drag and drop, or "}
                  <Box component={"span"} sx={Style.browseText}>
                    {isFile
                      ? // edit ?
                        // isFile?.name
                        // :
                        `${
                          fileName != files?.name ? fileName : files?.name
                        }.pdf`
                      : "browse"}
                  </Box>
                  {isFile ? " is ready to upload" : " your files"}
                </Typography>
              </Box>
            </FileUploader>
          </Box>
          <Box
            sx={Style.cancelInputButtonContainer}
            component={"form"}
            onSubmit={handleFormSubmit}
          >
            {isFile && edit && (
              <input
                value={inputName}
                onChange={(e) =>
                  setInputName(e?.target?.value?.split(".pdf")[0])
                }
                style={{ ...Style.buttonsContainer, ...Style.editInput }}
                placeholder="Enter File Name"
              />
            )}
            <Box sx={Style.saveButtonContainer}>
              {isFile && (
                <Box sx={{ ...Style.buttonsContainer, mt: 10 }}>
                  <CustomButton
                    type="submit"
                    // onClick={handleFormSubmit}
                    buttonText={edit ? "Save" : "Edit"}
                    sx={{ ...Style.cancelDocumentAction, mr: 1.5 }}
                  />
                </Box>
              )}
              {isFile && (
                <Box sx={{ ...Style.buttonsContainer, mt: 10 }}>
                  <CustomButton
                    disable={!edit && isFile?.name == file?.name}
                    onClick={handleCancel}
                    buttonText={"CANCEL"}
                    sx={Style.cancelDocumentAction}
                  />
                </Box>
              )}
              {!isAssignAble && (
                <Box
                  sx={{
                    ...Style.buttonsContainer,
                    ...Style.absContainer,
                    mt: 10,
                  }}
                >
                  <Box sx={{ ...Style.buttonsContainer, mt: { xs: 0 } }}>
                    <CustomButton
                      loading={loader}
                      color="secondary"
                      buttonText={!editItem ? "ADD" : "UPDATE"}
                      onClick={handleSubmit}
                      disable={
                        !!editItem
                          ? !department?.data?.length && !isFile
                          : !department?.data?.length || !isFile
                      }
                    />
                    <CustomButton
                      onClick={handleClose}
                      color="secondary"
                      buttonText={"CLOSE"}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
export default UploadDocumentModal;
