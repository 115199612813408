export const Style = {
  card: (isDrawer) => ({
    background: "#F7F8F9",
    borderTop: "4px solid #CFE4F3",
    borderBottom: "4px solid #CFE4F3",
    width: {
      xs: "100%",
      sm: isDrawer ? "100%" : "45%",
      md: isDrawer ? "47%" : "30%",
      lg: isDrawer ? "31%" : "22%",
      xl: isDrawer ? "23%" : "18%",
    },
    minWidth: "250px",
    maxWidth: { xs: "500px", lg: "400px" },
    height: "176px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    p: "10px",
    borderRadius: "8px",
    gap: "6px",
  }),
  img: {
    cursor: "pointer",
    height: '80px',
    borderRadius: '6px',
    objectFit: 'contain',
    alignSelf: "flex-start",
  },
  btnContainer: { display: "flex", alignSelf: "flex-end", gap: "5px" },
};
