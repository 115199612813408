import { globalStyle } from "../../styles/globalStyle";

export const Style = {
  quizContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardWrapper: (drawer) => ({
    display: "flex",
    justifyContent: { xs: "center", sm: "center", md: !drawer ? "center" : "start" },
    flexWrap: "wrap",
    gap: "26px",
    whiteSpace: 'normal',
    width: "100%",
    maxWidth: {xs: 'calc(100vw - 40px)', sm: drawer ? 'calc( 100vw - 305px )' : "calc(100vw - 100px)"},
    boxSizing: 'border-box'
  }),
  header: (activeComponent) => ({
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row" },
    alignItems: "center",
    justifyContent: !!activeComponent?.subHeading
      ? "space-between"
      : "flex-end",
    gap: "30px",
    p: "0 10px 10px",
    mb: activeComponent?.isDocument && "50px",
    position: "relative",
  }),
  subHeading: {
    ...globalStyle.headings,
    fontSize: { xs: "20px", sm: "28px" },
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    alignSelf: "flex-start",
  },
  trainingDepartmentNav: (activeComponent) => ({
    display: "flex",
    alignSelf: "space-between",
    gap: "16px",
    width: activeComponent?.isDepartment ? "100%" : "fit-content",
    justifyContent: "space-between",
  }),
  sortMain: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    position: "relative",
  },
  sortButton: {
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    p: "12px",
  },
};
