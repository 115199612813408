import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  formikValidation,
  getAllData,
  handleError,
} from "../../../hooks/globalFunction";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { signIn } from "../../../services/auth";
import { selectUser, updateUserData } from "../../../store/slice/user";
import { IMAGES } from "../../../theme";
import AuthDivider from "../../AuthDivider";
import CustomButton from "../../CustomButton";
import SocialAuth from "../../SocialAuth";
import ValidationError from "../../ValidationError";
import { Style } from "../style";

const SignInFrom = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Required!"),
      password: Yup.string().required("Required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { email, password } = values;
      try {
        const { data } = await signIn(dispatch, navigate, { email, password });
        dispatch(
          updateUserData({
            ...data?.user,
            refresh: data?.token.refresh,
            access: data?.token.access,
          })
        );
        localStorage.setItem("refresh", data?.token.refresh);
        localStorage.setItem("access", data?.token.access);
        localStorage.setItem("refreshTime", Date.now());
        localStorage.setItem("accessTime", Date.now());
        await getAllData(
          navigate,
          data?.token?.refresh,
          setLoading,
          dispatch,
          user,
          true
        );
        navigate("/dashboard");
        resetForm();
      } catch (err) {
        setLoading(false);
        toast.error(handleError({ error: "" }, err?.response?.data));
      }
    },
  });

  return (
    <Box sx={[Style.formSection, Style.columnContainer]}>
      {width < 500 && (
        <Box sx={Style.logoContainer}>
          <Box component={"img"} src={IMAGES.logo} sx={Style.logo} />
        </Box>
      )}
      <Box
        component="form"
        sx={Style.form}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Typography sx={Style.heading}>Sign In</Typography>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            type={"text"}
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.email && formik.touched.email && (
            <ValidationError error={formik?.errors?.email} />
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.password && formik.touched.password && (
            <ValidationError error={formik?.errors?.password} />
          )}
        </FormControl>
        <Typography
          onClick={() => navigate("/forget-password")}
          sx={Style.forgetPassword}
        >
          Forget Password
        </Typography>
        <CustomButton
          sx={{ width: "50%" }}
          buttonText={"SIGN IN"}
          type={"submit"}
          loading={loading}
          disable={formikValidation(formik)}
        />
        {/* <Typography sx={Style.redirectText}>
          Don’t have an account?{" "}
          <Box
            onClick={() => navigate("/signup")}
            component="span"
            sx={Style.redirect}
          >
            Sign Up
          </Box>
        </Typography> */}
        <AuthDivider text={"Sign in with"} />
        <SocialAuth />
      </Box>
    </Box>
  );
};

export default SignInFrom;
