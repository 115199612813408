import { Box, Typography } from "@mui/material";
import { globalStyle } from "../../../styles/globalStyle";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";

const ReuseModal = ({ open, setOpen, onConfirm, title, loader = false }) => {
  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <Typography sx={globalStyle.subHeading}>{title}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <CustomButton
          onClick={() => setOpen(false)}
          color="secondary"
          buttonText={"NO"}
        />
        <CustomButton
          onClick={onConfirm}
          color="secondary"
          buttonText={"YES"}
          loading={loader}
        />
      </Box>
    </ModalWrapper>
  );
};
export default ReuseModal;
