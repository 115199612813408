import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDepartmentUsers } from "../../../services/companies";
import { selectUser } from "../../../store/slice/user";
import { handleUserScroll } from "../../CustomTable/helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserSelect = ({ user, setUser, department, editItem }) => {
  const [tempUsers, setTempUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!user?.includes("all") && value?.includes("all")) {
      let tempData = [];
      userData?.results?.map((item) => {
        tempData?.push(item?.id);
      });
      setUser(tempData);
    } else if (user?.includes("all") && !value?.includes("all")) {
      setUser([]);
    } else if (value?.length < user?.length && user?.includes("all")) {
      setUser(value?.filter((item) => item != "all"));
    } else {
      setUser(typeof value === "string" ? value.split(",") : value);
    }
  };

  const { access, company_id } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, docId } = useParams();

  const getUser = async (id) => {
    const { data } = await getDepartmentUsers(dispatch, navigate, id, access);
    let temp = [...department?.data];
    let tempUserData = { ...userData };
    temp = temp?.map((item) => {
      let temp1 = { ...item };
      let users = [];
      tempUserData?.departments?.length
        ? !tempUserData?.departments?.includes(id) &&
          tempUserData?.departments?.push(id)
        : (tempUserData.departments = [id]);
      temp1?.users?.map((item2, index2) => {
        data?.results?.map((item3) => {
          if (item2?.includes(item3?.id)) {
            users.push(item3);
            let tempItem = { ...item3 };
            if (!!tempUserData?.results) {
              let ind = tempUserData?.results?.findIndex(
                (item) => item?.id == item3?.id
              );
              tempItem.departId =
                ind == -1
                  ? [id]
                  : !tempUserData?.results[ind].departId?.includes(id)
                  ? [...tempUserData?.results[ind].departId, id]
                  : [...tempUserData?.results[ind].departId];
              tempUserData.results = !tempUserData?.results?.length
                ? [tempItem]
                : ind == -1
                ? [...tempUserData?.results, tempItem]
                : [
                    ...tempUserData?.results.slice(0, ind),
                    tempItem,
                    ...tempUserData?.results.slice(ind + 1),
                  ];
            } else {
              tempItem.departId = [id];
              tempUserData.results = [tempItem];
            }
          }
        });
      });
      temp1.users = users;
      return temp1;
    });
    if (
      !!tempUserData?.results?.length &&
      tempUserData?.results?.findIndex((item) => item?.id == "all") == -1
    )
      tempUserData?.results?.unshift({
        first_name: "All",
        last_name: "Users",
        id: "all",
      });
    setTempUsers(tempUserData);
    setUserData(tempUserData);
    let tempData = editItem?.file?.assigned_users || tempUserData?.results;
    tempData?.map((item) =>
      setUser((prev) =>
        (typeof item == "object" ? prev?.includes(item?.id) : prev?.includes(item)) ? [...prev] : [...prev, typeof item == "object" ? item?.id : item]
      )
    );
  };
  useEffect(() => {
    let departId = department?.data[department?.data?.length - 1]?.id;
    let tempData = { ...tempUsers };
    let tempUserData = { ...userData };
    if (
      !!department?.data?.length &&
      !tempUsers?.departments?.includes(departId)
    )
      getUser(departId);
    else if (department?.status == "remove") {
      tempUserData.results = tempUserData?.results?.map((item) => {
        if (item.id !== "all") {
          item.departId = item?.departId?.filter(
            (item2) =>
              department?.data?.findIndex((item3) => item2 === item3?.id) !== -1
          );
        }
        return item;
      });
      tempUserData.results = tempUserData?.results?.filter(
        (item) => (tempUserData?.results?.length != 1 && item.id === "all") || item?.departId?.length > 0
      );
      tempUserData.departments = tempUserData?.departments?.filter(
        (item, index) => item != departId
      );
      setUserData(tempUserData);
      let tempUser = [];
      let data = editItem?.file?.assigned_users || tempUserData?.results;
      data?.map((item) => tempUser.push( typeof item == "object" ? item?.id : item));
      setUser(tempUser);
    } else {
      let array = [{ first_name: "All", last_name: "Users", id: "all" }];
      tempData?.results?.map((item, index) => {
        let ind = tempUserData?.results?.findIndex(
          (item2) => item2?.id == item?.id
        );
        let departInd = item?.departId?.findIndex((item2) => item2 == departId);
        let tempItem = { ...tempUserData?.results[ind] };
        let userDepartInd = tempItem?.departId?.findIndex(
          (item2) => item2 == departId
        );
        if (departInd == -1 && ind != -1) {
          tempItem?.departId?.push(departId);
          array.push(tempItem);
        } else if (ind == -1) {
          let temp = { ...item };
          temp.departId = [departId];
          array.push(temp);
        }
      });
      setUserData((prev) => {
        let data = { ...prev, results: array };
        return data;
      });
      let tempUser = [];
      let tempDatas = editItem?.file?.assigned_users || array;
      tempDatas?.map((item) => tempUser.push( typeof item == "object" ? item?.id : item));
      setUser(tempUser);
    }
  }, [
    // id, company_id, 
    department?.data?.length]);

  const renderValue = (selected) => {
    return selected
      .map((id) => {
        const userItem =
          userData && userData?.results?.find((item) => item.id === id);
        if (
          !selected?.includes(`${userItem?.first_name} ${userItem?.last_name}`)
        )
          return userItem
            ? `${userItem?.first_name} ${userItem?.last_name}`
            : "";
      })
      .join(", ");
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{!userData?.results?.length ? "No User Found" : "User"}</InputLabel>
      <Select
        sx={{
          width: "100%",
          mb: "16px",
        }}
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={user}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={MenuProps}
        disabled={!userData?.results?.length}
        label={!userData?.results?.length ? "No User Found" : "User"}  
      >
        {!!userData?.results &&
          userData?.results?.map((item, index) => {
            const name = item?.first_name + " " + item?.last_name;
            return (
              <MenuItem key={index} value={item?.id}>
                <Checkbox checked={user.indexOf(item?.id) > -1} />
                <ListItemText
                  sx={{
                    "& .MuiTypography-root": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                  primary={name}
                />
              </MenuItem>
            );
          })}
        {loading && (
          <InView
            onChange={(view, entry) => {
              if (!userData?.next) setLoading(false);
              else if (view)
                handleUserScroll(
                  access,
                  dispatch,
                  setLoading,
                  userData,
                  user,
                  navigate
                );
            }}
          >
            <MenuItem
              value=""
              sx={{ display: "flex", justifyContent: "center", height: "40px" }}
            >
              <CircularProgress />
            </MenuItem>
          </InView>
        )}
      </Select>
    </FormControl>
  );
};

export default UserSelect;
