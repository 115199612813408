export const Style = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    background: "#e6e6fb",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "20px 0 20px 0",
  },
  headerIcon: {
    width: { xs: 100, sm: 125 },
    height: { xs: 100, sm: 125 },
    color: "#3346D3",
  },
  listItemBtnContainer: (open) => ({
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
  }),
  listItemIcon: (open) => ({
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
  }),
  signoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    height: 40,
  },
  signoutText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  logoContainer: {
    background: "#3346D3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    height: 60,
  },
  logo: { width: "200px", height: "30px" },
  childrenWrapper: {
    flexGrow: 1,
    height: "100vh",
    width: "100%",
  },
  bgHeader: (drawer) => ({
    position: "fixed",
    width: "100%",
    height: { xs: 0, sm: 60 },
    background: "#3346D3",
  }),
  childrenContainer: (drawer) => ({
    position: "relative",
    zIndex: "999",
    display: "flex",
    flexDirection: "column",
    background: "white",
    minHeight: "100%",
    mt: { xs: 0, sm: "30px" },
    borderTopLeftRadius: { xs: 0, sm: drawer ? 16 : 0 },
    borderTopRightRadius: { xs: 0, sm: drawer ? 16 : 0 },
    p: { xs: 1.5, md: 3 },
  }),
  smallScreenSlider: {
    width: 250,
    background: "#e6e6fb",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
  },
  userNameContainer: { display: "flex", alignItems: "center", gap: "8px" },
  editIcon: { cursor: "pointer", fontSize: "20px" },
  text: {
    wordWrap: "break-word !important",
    width: "200px",
    fontSize: { xs: "16px", sm: "20px" },
    boxSizing: "border-box",
    display: "flex",
  },
  ellipse: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    maxWidth: "190px",
  },
};
