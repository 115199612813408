export const Style = {
  header: {
    borderRadius: "6px",
    height: "50px",
    backgroundColor: "#10194B",
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: "20px",
  },
  buttonsContainer: {
    marginTop: { xs: "16px", md: "0px" },
    display: "flex",
    justifyContent: { xs: "center", md: "flex-end" },
    gap: "15px",
  },
};
