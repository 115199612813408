import { COLORS } from "../../theme";

export const Style = {
  formSection: {
    width: { xs: "100%", md: "40%" },
    color: COLORS.black,
    px: "30px",
  },
  columnContainer: { display: "flex", flexDirection: "column" },
  form: {
    fontFamily: "serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
  },
  heading: {
    fontWeight: 900,
    fontSize: { xs: "24px", md: "40px" },
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  forgetPassword: {
    fontWeight: 500,
    display: "flex",
    alignSelf: "flex-end",
    color: "#254AA5",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  redirectText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 1.5,
    letterSpacing: "2px",
  },
  redirect: { cursor: "pointer", textDecoration: "underline" },
  logoContainer: {
    background: "#3346D3",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    height: "60px",
    width: "210px",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  logo: {
    width: "200px",
    height: "30px",
    padding: "10px",
  },
};
