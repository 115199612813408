export const Style = {
  main: (rows) => ({
    width: {
      xs: "100%",
      md: "calc(50% - 20px)",
      lg: "calc(50% - 30px)",
    },
    background: "white",
    boxSizing: "border-box",
    height: {
      xs: "calc( (100vh - 65px) * 0.5 )",
      md: "calc( 100vh - 460px )",
    },
    minHeight: "300px",
    maxHeight: `484px`,
  }),
  tittleContainer: {
    width: "100%",
    display: "flex",
    px: 2,
    justifyContent: "space-between",
    alignItems: "center",
    background: "#12385D",
    height: "52px",
    color: "#fff",
  },
  menuIcon: { fontSize: 24, cursor: "pointer", mr: "5px" },
  tittle: { fontSize: "28px", fontFamily: "Literata", width: '100%', display: 'flex', justifyContent: 'center' },
  tableContainer: {
    display: "flex",
    maxWidth: "100%",
    minHeight: "260px",
    height: {
      xs: "calc( (100vh - 170px) * 0.5 )",
      md: "calc( 100vh - 520px )",
    },
    "& .MuiDataGrid-scrollbar": {
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#12385D",
        borderRadius: "6px",
      },
    },
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tableBody: { width: "100%", minWidth: "223px" },
  tableRow: {
    "&:last-child td, &:last-child th": {
      border: 0,
      width: "100%",
    },
  },
  tableCell: { width: "40px !important" },
  deleteIcon: (isDeleteDisable) => ({
    cursor: isDeleteDisable ? "pointer" : "not-allowed",
    fill: isDeleteDisable ? "white" : "rgba(255, 255, 255, 0.3)"
  })
};
